import React from "react";
import { Tag } from "carbon-components-react";

const Group = {
  dependencies: (
    <Tag type="magenta" className="group__name_tag">
      dependencies
    </Tag>
  ),
  consumers: (
    <Tag type="blue" className="group__name_tag">
      consumers
    </Tag>
  ),
  services: (
    <Tag type="green" className="group__name_tag">
      services
    </Tag>
  ),
  tasks: (
    <Tag type="cyan" className="group__name_tag">
      tasks
    </Tag>
  ),
  streamers: (
    <Tag type="teal" className="group__name_tag">
      streamers
    </Tag>
  ),
};

const ServiceGroup = ({ value }) => {
  return Group[value];
};

export default ServiceGroup;
