import React from "react";
import { Row, Column, Tile, Button, TextInput } from "carbon-components-react";

import {
  Edit20 as Edit,
  Save20 as Save,
  Close20 as Close,
} from "@carbon/icons-react";

import LabeledData from "../../components/LabeledData";

const EmailView = (props) => (
  <>
    <Button
      size="sm"
      className="user-detail__button_icon"
      kind="ghost"
      role="button"
      onClick={props.onEdit}
    >
      <Edit className="user-detail__icon-actions" />
    </Button>
    <Row className="user-detail__row_data">
      <Column lg={10}>
        <LabeledData labelText="Email" value={props.email} />
      </Column>
    </Row>
  </>
);

const EmailEdit = (props) => (
  <>
    <div className="user-detail__button_icon">
      <Button
        size="sm"
        onClick={props.onSubmit}
        role="button"
        kind="ghost"
        className="user-detail__save-button"
      >
        <Save
          className="user-detail__icon-actions"
          description="Save changes"
        />
      </Button>
      <Button
        size="sm"
        onClick={props.onCancel}
        role="button"
        kind="ghost"
        className="user-detail__save-button"
      >
        <Close
          className="user-detail__icon-actions"
          description="Cancel changes"
        />
      </Button>
    </div>
    <Row className="user-detail__row_data">
      <Column lg={10}>
        <TextInput
          light
          labelText="Email"
          value={props.email}
          onChange={(e) => props.setEmail(e.target.value)}
        />
      </Column>
    </Row>
  </>
);

const UserEmailTile = ({ user, onSave }) => {
  const [mode, setMode] = React.useState("view");
  const [email, setEmail] = React.useState(
    user.email ? user.emails[0].email : ""
  );

  const onCancel = () => {
    setEmail(user.emails[0].email);
    setMode("view");
  };

  const onEdit = () => {
    setMode("edit");
  };

  const onSubmit = () => {
    onSave({ email });
    setMode("view");
  };

  return (
    <Tile className="user-detail__tile_phone_email">
      <h3 className="user-detail__tile_header">Email Address</h3>
      {mode === "view" && <EmailView email={email} onEdit={onEdit} />}
      {mode === "edit" && (
        <EmailEdit
          setEmail={setEmail}
          email={email}
          onSubmit={onSubmit}
          onCancel={onCancel}
        />
      )}
    </Tile>
  );
};

export default UserEmailTile;
