import { CheckmarkFilled16 as CheckmarkFilled, CloseFilled16 as CloseFilled } from '@carbon/icons-react';

const Status = {
	true: <CheckmarkFilled className="status__icon-green" />,
	false: <CloseFilled className="status__icon-red" />,
};

const CheckStatus = ({ value }) => {
	return Status[value];
};

export default CheckStatus;
