import React from "react";

import { TooltipIcon } from "carbon-components-react";

import {
  ErrorFilled16 as Error,
  WarningAltFilled16 as Warning,
  CircleFilled16 as Circle,
} from "@carbon/icons-react";

const Status = {
  0: (
    <TooltipIcon direction="top" tooltipText="Stopped">
      <Error className="services__failed" />
    </TooltipIcon>
  ),
  running: (
    <TooltipIcon direction="top" tooltipText="Starting">
      <Circle className="services__running" />
    </TooltipIcon>
  ),
  stopped: (
    <TooltipIcon direction="top" tooltipText="Stopped">
      <Error className="services__failed" />
    </TooltipIcon>
  ),
  failed: (
    <TooltipIcon direction="top" tooltipText="Failed">
      <Error className="services__failed" />
    </TooltipIcon>
  ),
  10: (
    <TooltipIcon direction="top" tooltipText="Starting">
      <Circle className="services__running" />
    </TooltipIcon>
  ),
  20: (
    <TooltipIcon direction="top" tooltipText="Running">
      <Circle className="services__running" />
    </TooltipIcon>
  ),
  30: (
    <TooltipIcon direction="top" tooltipText="Backed Off">
      <Warning className="services__warning" />
    </TooltipIcon>
  ),
  40: (
    <TooltipIcon direction="top" tooltipText="Stopping">
      <Error className="services__failed" />
    </TooltipIcon>
  ),
  100: (
    <TooltipIcon direction="top" tooltipText="Exited">
      <Error className="services__failed" />
    </TooltipIcon>
  ),
  200: (
    <TooltipIcon direction="top" tooltipText="Fatal">
      <Error className="services__failed" />
    </TooltipIcon>
  ),
};

const ServiceStatus = ({ value }) => {
  return Status[value];
};

export default ServiceStatus;
