import React from 'react';
import { Tile, Pagination } from 'carbon-components-react';

import { AuthContext } from '../../store';
import { adminService } from '../../services';

import PaymentsTable from '../../components/PaymentsTable';

const headers = [
	{ key: 'paymentId', header: 'Payment ID' },
	{ key: 'paymentReference', header: 'Payment Reference' },
	{ key: 'paymentType', header: 'Payment Type' },
	{ key: 'paymentDate', header: 'Payment Date' },
	{ key: 'paymentAmount', header: 'Payment Amount' },
	{ key: 'paymentStatus', header: 'Payment Status' },
	{ key: 'poolId', header: 'Pool ID' },
	{ key: 'updatedAt', header: 'Timestamp' },
];

const UserPaymentsTile = ({ userId }) => {
	const [{ token }] = React.useContext(AuthContext);
	const [data, setData] = React.useState([]);
	const [totalItems, setTotalItems] = React.useState(0);

	const paginate = ({ page, pageSize }) => {
		adminService
			.post('/fetchUserPayments', { token, userId, offset: (page - 1) * pageSize, limit: pageSize })
			.then((data) => {
				if (data.ok) {
					setTotalItems(data.rowLength);
					setData(data.rows);
				}
			});
	};

	React.useEffect(() => {
		const fetchPayments = () => {
			adminService.post('/fetchUserPayments', { token, userId }).then((data) => {
				if (data.ok) {
					setTotalItems(data.rowLength);
					setData(data.rows);
				}
			});
		};
		fetchPayments();
	}, [token, userId]);

	return (
		<Tile className="user-detail__tile_payments">
			<h3 className="user-detail__tile_header">Payments</h3>
			<div className="user-payment__table">
				{totalItems > 0 && (
					<>
						<PaymentsTable key="users" size="normal" headers={headers} data={data} />
						<Pagination
							onChange={paginate}
							pageSize={10}
							totalItems={totalItems}
							page={1}
							pageSizes={[10, 20, 30, 40, 50]}
						/>
					</>
				)}
				{totalItems === 0 && <p className="user-detail__no_items">There are no payments for this user yet!</p>}
			</div>
		</Tile>
	);
};

export default UserPaymentsTile;
