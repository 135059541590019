import React from "react";
import {
  Row,
  Column,
  Tile,
  Button,
  TextInput,
  InlineLoading,
  FileUploaderDropContainer,
  Tabs,
  Tab,
  Dropdown,
  FileUploaderItem,
} from "carbon-components-react";

import { v4 as uuid } from "uuid";

import {
  Save16 as Save,
  Close16 as Close,
  Attachment20 as Upload,
} from "@carbon/icons-react";

import { AuthContext } from "../../store";
import { adminService, uploadFile } from "../../services";

import LabeledData from "../../components/LabeledData";

const DOCS = {
  1: "Passport",
  2: "Driver's License",
  3: "Voter's ID",
  4: "Social Insurance",
  5: "National ID",
  6: "Taxpayer ID",
};

const DisplayDocument = ({ row }) => {
  const [fileData, setFileData] = React.useState("");
  const [{ token }] = React.useContext(AuthContext);

  React.useEffect(() => {
    const data = { token, url: row.documentUrl };
    const fetchImageData = async () => {
      adminService.fetchFile(data).then((file) => {
        if (file) setFileData(file);
      });
    };
    if (row.documentUrl.includes("digitalocean")) {
      fetchImageData();
    } else {
      setFileData(row.documentUrl);
    }
  }, [token, row]);

  return (
    <>
      <Row>
        <Column lg={16}>
          <Row>
            <Column lg={{ span: 10, offset: 5 }}>
              <div className="user-detail__zoom">
                <img
                  src={fileData}
                  className="user-detail__view_media"
                  alt={DOCS[row.document_type]}
                />
                {fileData === "" && (
                  <InlineLoading
                    status="error"
                    description="Document download failed..."
                  />
                )}
              </div>
            </Column>
          </Row>
        </Column>
      </Row>
      <Row className="user-detail__row_data">
        <Column lg={4}>
          <LabeledData
            labelText="Document Type"
            value={DOCS[row.documentType]}
          />
        </Column>
        <Column lg={4}>
          <LabeledData labelText="Document Number" value={row.documentNumber} />
        </Column>
      </Row>
    </>
  );
};

const DocumentView = (props) => (
  <>
    <Button
      size="sm"
      className="user-detail__button_icon"
      kind="ghost"
      role="button"
      onClick={props.onUpload}
    >
      <Upload
        className="user-detail__icon-actions"
        description="Edit profile"
      />
    </Button>
    {props.loading && <InlineLoading description="Loading..." />}
    {!props.loading && (
      <>
        {props.failed && (
          <InlineLoading
            status="error"
            description="Error loading documents data..."
          />
        )}
        {!props.failed && (
          <div>
            {props.rowLength > 0 && (
              <Tabs type="container" light={true}>
                {props.rows.map((row, i) => (
                  <Tab
                    key={i}
                    id={row.documentId}
                    label={DOCS[row.documentType]}
                  >
                    <DisplayDocument row={row} />
                  </Tab>
                ))}
              </Tabs>
            )}
            {props.rowLength === 0 && (
              <p className="user-detail__no_items">
                User has not added any documents yet!
              </p>
            )}
          </div>
        )}
      </>
    )}
  </>
);

const DocumentUpload = (props) => (
  <>
    <div className="user-detail__button_icon">
      <Button
        size="sm"
        onClick={props.onSubmit}
        role="button"
        kind="ghost"
        className="user-detail__save-button"
      >
        <Save
          className="user-detail__icon-actions"
          description="Save changes"
        />
      </Button>
      <Button
        size="sm"
        onClick={props.onCancel}
        role="button"
        kind="ghost"
        className="user-detail__save-button"
      >
        <Close
          className="user-detail__icon-actions"
          description="Cancel changes"
        />
      </Button>
    </div>
    <Row>
      <Column lg={16}>
        <Row>
          <Column lg={{ span: 10, offset: 5 }}>
            <div className="bx--file__container">
              {!props.documentFile && (
                <>
                  <strong className="bx--file--label user-detail__upload_header">
                    Upload verification document
                  </strong>
                  <p className="bx--label-description user-detail__upload_text">
                    Only .jpg and .png files. 500kb max file size
                  </p>
                  <FileUploaderDropContainer
                    accept={["image/jpeg", "image/png"]}
                    labelText="Drag and drop files here or click to upload"
                    tabIndex={0}
                    multiple={false}
                    className="user-detail__upload_area"
                    name="documentFile"
                    onAddFiles={props.handleInput}
                  />
                </>
              )}
              {props.documentFile && (
                <>
                  <FileUploaderItem
                    name={props.documentFile.name}
                    status="edit"
                    onDelete={(e) => {
                      const evt = {
                        target: { files: [null], name: "documentFile" },
                      };
                      props.handleInput(evt);
                    }}
                    errorSubject="File size exceeds limits"
                    errorBody="500 kb max file size. Select a new file and try again."
                  />
                  <img
                    className="user__document_photo"
                    src={props.localUrl}
                    alt="Document"
                  />
                </>
              )}
            </div>
          </Column>
        </Row>
      </Column>
    </Row>
    <Row className="user-detail__row_data">
      <Column lg={6}>
        <Dropdown
          light
          ariaLabel="Dropdown"
          id="document-dropdown"
          label="Select document type"
          titleText="Document Type"
          items={Object.keys(DOCS)}
          itemToString={(item) => DOCS[item]}
          name="documentType"
          value={props.documentType}
          onChange={props.handleInput}
        />
      </Column>
      <Column lg={6}>
        <TextInput
          labelText="Document Number"
          light
          name="documentNumber"
          value={props.documentNumber}
          onChange={props.handleInput}
        />
      </Column>
    </Row>
  </>
);

const UserDocumentTile = ({ user, onSave }) => {
  const [state] = React.useContext(AuthContext);
  const { token } = state;

  const [documents, setDocuments] = React.useState({ rowLength: 0 });
  const [status, setStatus] = React.useState({ failed: false, loading: true });
  const [userId] = React.useState(user.userId);

  const [mode, setMode] = React.useState("view");
  const [document, setDocument] = React.useState({});

  React.useEffect(() => {
    const loadDocuments = async () => {
      adminService
        .post("/fetchUserDocuments", { token, userId })
        .then((data) => {
          if (data.ok) {
            setDocuments(data);
            setStatus({ failed: false, loading: false });
          } else {
            setStatus({ failed: true, loading: false });
          }
        })
        .catch((e) => {
          console.log(e);
          setStatus({ failed: true, loading: false });
        });
    };
    loadDocuments();
  }, [token, userId]);

  const onUpload = () => {
    setMode("upload");
  };

  const onCancel = () => {
    setDocument({});
    setMode("view");
  };

  const onSubmit = () => {
    const { documentType, documentNumber, documentFile } = document;

    // upload to firebase
    uploadFile({ bucket: userId, documentFile }).then((documentUrl) => {
      onSave({ documentType, documentUrl, documentNumber });
      let data = {
        rows: [
          {
            documentId: uuid(),
            documentType: documentType,
            documentUrl: documentUrl,
            documentNumber: documentNumber,
          },
        ],
        rowLength: documents.rowLength + 1,
      };
      data.rows.push(...documents.rows);
      setDocuments(data);
      onCancel();
    });
  };

  const handleInput = (e) => {
    var name, value;
    var data = {};
    if (e.target) {
      const { target } = e;
      name = target.name;
      value = target.value;
      data = { [name]: value };
      if (name === "documentFile") {
        value = target.files[0];
        let localUrl = null;
        if (value) localUrl = URL.createObjectURL(value);
        data = { [name]: value, localUrl };
      }
    } else {
      name = "documentType";
      value = parseInt(e.selectedItem);
      data = { [name]: value };
    }
    setDocument({ ...document, ...data });
  };

  return (
    <Tile className="user-detail__tile_documents">
      <h3 className="user-detail__tile_header">Documents</h3>
      {mode === "view" && (
        <DocumentView
          onUpload={onUpload}
          {...status}
          rowLength={documents.rowLength}
          rows={documents.rows}
        />
      )}
      {mode === "upload" && (
        <DocumentUpload
          onSubmit={onSubmit}
          onCancel={onCancel}
          {...document}
          handleInput={handleInput}
        />
      )}
    </Tile>
  );
};

export default UserDocumentTile;
