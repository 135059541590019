import React from "react";

import { Tabs, Tab, Tile, CodeSnippet } from "carbon-components-react";
import UsageChart from "./UsageChart";

import { AuthContext } from "../../store";
import { adminService } from "../../services";

const ServiceExpanded = ({ containerId }) => {
  const [{ token }] = React.useContext(AuthContext);

  const [stats, setStats] = React.useState([]);
  const [logs, setLogs] = React.useState([]);
  const [counter, setCounter] = React.useState(0);

  React.useEffect(() => {
    const fetchLogs = async () => {
      adminService
        .post("/fetchServiceLogs", { token, containerId })
        .then((data) => {
          if (data.ok) {
            setLogs([...data.rows[0].logs]);
          }
        });
    };
    // fetchLogs();
    const timer = setInterval(() => fetchLogs(), 60000);
    return () => clearInterval(timer);
  }, [token, containerId]);

  React.useEffect(() => {
    const fetchStats = async () => {
      adminService
        .post("/fetchServiceStats", { token, containerId })
        .then((data) => {
          if (data.ok) {
            if (counter > 100) {
              setStats([...stats.slice(2, 0), ...data.rows]);
            } else {
              setStats([...stats, ...data.rows]);
            }
            setCounter(counter + 1);
          }
        });
    };
    // fetchStats();
    const timer = setInterval(() => fetchStats(), 60000);
    return () => clearInterval(timer);
  }, [stats, counter, token, containerId]);

  return (
    <Tabs light={true}>
      <Tab id="service-statistics" label="Statistics">
        <Tile className="services-table__tab">
          <UsageChart data={stats} />
        </Tile>
      </Tab>
      <Tab id="service-logs" label="Logs">
        <Tile className="services-table__tab">
          <CodeSnippet type="multi">
            {logs.length > 0 ? logs.join("\n") : ""}
          </CodeSnippet>
        </Tile>
      </Tab>
    </Tabs>
  );
};

export default ServiceExpanded;
