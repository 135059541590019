import React from "react";
import {
  StructuredListRow,
  StructuredListCell,
  Tile,
  Button,
  InlineLoading,
  TextArea,
  StructuredListWrapper,
  StructuredListBody,
  StructuredListHead,
} from "carbon-components-react";

import {
  AddAlt20 as Add,
  Save20 as Save,
  Close20 as Close,
} from "@carbon/icons-react";

import { AuthContext } from "../../store";
import { adminService } from "../../services";
import { formatGMTDate } from "../../utils";

const UserNotesView = (props) => (
  <>
    <Button
      size="sm"
      className="user-detail__button_icon"
      kind="ghost"
      role="button"
      onClick={props.onEdit}
    >
      <Add className="user-detail__icon-actions" description="Add address" />
    </Button>
    {props.loading && <InlineLoading description="Loading..." />}
    {!props.loading && (
      <>
        {props.failed && (
          <InlineLoading description="Loading error" status="error" />
        )}
        {!props.failed && (
          <>
            {props.notes.rowLength > 0 && (
              <div className="user-detail__notes_box">
                <StructuredListWrapper>
                  <StructuredListHead>
                    <StructuredListRow head tabIndex={0}>
                      <StructuredListCell head>
                        Administrator ID
                      </StructuredListCell>
                      <StructuredListCell head>Notes</StructuredListCell>
                      <StructuredListCell head>Date</StructuredListCell>
                    </StructuredListRow>
                  </StructuredListHead>
                  <StructuredListBody>
                    {props.notes.rows.map((row, i) => (
                      <React.Fragment key={i}>
                        <StructuredListRow key={i} tabIndex={0}>
                          <StructuredListCell>
                            {row.adminId.slice(0, 8)}...
                          </StructuredListCell>
                          <StructuredListCell>{row.notes}</StructuredListCell>
                          <StructuredListCell className="data-table-cell--date-time">
                            {formatGMTDate(row.createdAt)}
                          </StructuredListCell>
                        </StructuredListRow>
                      </React.Fragment>
                    ))}
                  </StructuredListBody>
                </StructuredListWrapper>
              </div>
            )}
            {props.notes.rowLength === 0 && (
              <p className="user-detail__no_items">
                There are no notes for this user!
              </p>
            )}
          </>
        )}
      </>
    )}
  </>
);

const UserNotesEdit = (props) => (
  <>
    <div className="user-detail__button_icon">
      <Button
        size="sm"
        onClick={props.onSubmit}
        role="button"
        kind="ghost"
        className="user-detail__save-button"
      >
        <Save
          className="user-detail__icon-actions"
          description="Save changes"
        />
      </Button>
      <Button
        size="sm"
        onClick={props.onCancel}
        role="button"
        kind="ghost"
        className="user-detail__save-button"
      >
        <Close
          className="user-detail__icon-actions"
          description="Cancel changes"
        />
      </Button>
    </div>
    <TextArea
      light
      rows={9}
      value={props.text}
      onChange={(e) => props.setText(e.target.value)}
    />
  </>
);

const UserNotesTile = ({ user, onSave }) => {
  const [{ token }] = React.useContext(AuthContext);
  const [userId] = React.useState(user.userId);

  const [notes, setNotes] = React.useState({ rowLength: 0 });
  const [status, setStatus] = React.useState({ failed: false, loaded: false });

  const [mode, setMode] = React.useState("view");
  const [text, setText] = React.useState("");

  React.useEffect(() => {
    const loadNotes = async () => {
      adminService
        .post("/fetchUserNotes", { token, userId })
        .then((data) => {
          if (data.ok) {
            setNotes(data);
            setStatus({ failed: false, loading: false });
          } else {
            setStatus({ failed: true, loading: false });
          }
        })
        .catch((e) => {
          setStatus({ failed: true, loading: false });
        });
    };
    loadNotes();
  }, [token, userId]);

  const onCancel = () => {
    setMode("view");
  };

  const onEdit = () => {
    setMode("edit");
  };

  const onSubmit = () => {
    onSave({ notes: text });
    setText("");
    setMode("view");
  };

  return (
    <Tile className="user-detail__tile_notes">
      <h3 className="user-detail__tile_header">Notes</h3>
      {mode === "view" && (
        <UserNotesView {...status} notes={notes} onEdit={onEdit} />
      )}
      {mode === "edit" && (
        <UserNotesEdit
          onSubmit={onSubmit}
          onCancel={onCancel}
          text={text}
          setText={setText}
        />
      )}
    </Tile>
  );
};

export default UserNotesTile;
