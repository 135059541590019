import React from 'react';

import { ClickableTile } from 'carbon-components-react';
import { Dashboard32, AppConnectivity32, Wallet32, Group32, Partnership32 } from '@carbon/icons-react';

const DashboardTile = ({ onClick }) => (
	<ClickableTile className="home-page__tile" handleClick={onClick}>
		<h3 className="home-page__tile-header">View Dashboards</h3>
		<p className="home-page__tile-body">View dashboard and track app growth.</p>
		<Dashboard32 className="home-page__tile-icon" />
	</ClickableTile>
);

const ServicesTile = ({ onClick }) => (
	<ClickableTile className="home-page__tile" handleClick={onClick}>
		<h3 className="home-page__tile-header">Services</h3>
		<p className="home-page__tile-body">Monitor services health.</p>
		<AppConnectivity32 className="home-page__tile-icon" />
	</ClickableTile>
);

const PaymentsTile = ({ onClick }) => (
	<ClickableTile className="home-page__tile-half" handleClick={onClick}>
		<h3 className="home-page__tile-header">Payments</h3>
		<p className="home-page__tile-body">Manage payments.</p>
		<Wallet32 className="home-page__tile-icon" />
	</ClickableTile>
);

const PoolsTile = ({ onClick }) => (
	<ClickableTile className="home-page__tile" handleClick={onClick}>
		<h3 className="home-page__tile-header">Manage Pools</h3>
		<p className="home-page__tile-body">Close pool and remove members.</p>
		<Partnership32 className="home-page__tile-icon-half" />
	</ClickableTile>
);

const UsersTile = ({ onClick }) => (
	<ClickableTile className="home-page__tile" handleClick={onClick}>
		<h3 className="home-page__tile-header">Administer Users</h3>
		<p className="home-page__tile-body">Add user information, verify and block users.</p>
		<Group32 className="home-page__tile-icon-half" />
	</ClickableTile>
);

export { DashboardTile, ServicesTile, PaymentsTile, PoolsTile, UsersTile };
