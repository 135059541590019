//const URL = 'https://susupond.ngrok.io/admin';
import getExtension from "./decoder";

const URL = "https://dev.susupond.com";
const Urls = {
  admin: `${URL}/admin`,
  monitor: `${URL}/monitor`,
};

const monitorPaths = [
  "/fetchServices",
  "/fetchServiceLogs",
  "/fetchServiceStats",
];

function getUrl(path) {
  var key = monitorPaths.includes(path) ? "monitor" : "admin";
  return `${Urls[key]}${path}`;
}

async function post(path = "", data = {}) {
  try {
    const response = await fetch(getUrl(path), {
      crossDomain: true,
      method: "POST",
      mode: "cors",
      credentials: "same-origin",
      headers: { "Content-Type": "application/json" },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer",
      body: JSON.stringify(data),
    });
    data = await response.json();
    if (!response.ok) {
      console.log("response not ok", data);
    }
    return data;
  } catch (err) {
    console.log(err);
    return { ok: false, data: err };
  }
}

async function get(path = "", data = {}) {
  const response = await fetch(getUrl(path) + path, {
    method: "GET",
    mode: "cors",
    credentials: "same-origin",
    headers: { "Content-Type": "application/json" },
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer",
    body: JSON.stringify(data),
  });
  return response.json();
}

async function fetchFile({ token, url }) {
  try {
    const { ok, data } = await post("/fetchFile", { token, url });
    var ext = undefined;
    if (ok) ext = getExtension(data);
    console.log("extension", ext);
    if (!ok) throw data;
    else return `data:${ext};base64, ${data}`;
  } catch (err) {
    throw err;
  }
}

const adminService = { post, get, fetchFile };

export default adminService;
