import React from 'react';
import { Tile, Pagination } from 'carbon-components-react';

import { AuthContext } from '../../store';
import { adminService } from '../../services';

import GenericTable from '../../components/GenericTable';

const headers = [
	{ key: 'id', header: 'Cycle ID' },
	{ key: 'startDate', header: 'Start Date' },
	{ key: 'endDate', header: 'End Date' },
	{ key: 'completed', header: 'Completed' },
	{ key: 'closed', header: 'Closed' },
	{ key: 'createdAt', header: 'Created' },
];

const PoolCyclesTile = ({ poolId }) => {
	const [{ token }] = React.useContext(AuthContext);
	const [data, setData] = React.useState([]);
	const [totalItems, setTotalItems] = React.useState(0);

	const paginate = ({ page, pageSize }) => {
		adminService
			.post('/fetchPoolCycles', { token, poolId, offset: (page - 1) * pageSize, limit: pageSize })
			.then((data) => {
				if (data.ok) {
					setTotalItems(data.rowLength);
					setData(data.rows);
				}
			});
	};

	React.useEffect(() => {
		const fetchCycles = () => {
			adminService.post('/fetchPoolCycles', { token, poolId }).then((data) => {
				if (data.ok) {
					setTotalItems(data.rowLength);
					setData(data.rows);
				}
			});
		};
		fetchCycles();
	}, [token, poolId]);

	return (
		<Tile className="pool-detail__tile_cycles">
			<h3 className="pool-detail__tile_header">Cycles</h3>
			<div className="pool-cycle__table">
				{totalItems > 0 && (
					<>
						<GenericTable key="cycles" size="normal" headers={headers} data={data} />
						<Pagination
							onChange={paginate}
							pageSize={10}
							totalItems={totalItems}
							page={1}
							pageSizes={[10, 20, 30, 40, 50]}
						/>
					</>
				)}
				{totalItems === 0 && <p className="pool-detail__no_items">There are no cycles for this pool yet!</p>}
			</div>
		</Tile>
	);
};

export default PoolCyclesTile;
