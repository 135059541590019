import React from 'react';
import { Transition } from 'react-transition-group';
import { Row, Column, Modal, Button, ButtonSet, InlineNotification, Tabs, Tab } from 'carbon-components-react';

import { CloseOutline24 as Close } from '@carbon/icons-react';

import { AuthContext } from '../../store';
import { adminService } from '../../services';
import PoolStatus from '../../components/PoolStatus';
import PoolType from '../../components/PoolType';

import { ContentGrid, ContentContainer } from '../../components/ContentContainer';
import Breadcrumbs from '../../components/Breadcrumbs';

import PoolDetailsTile from './PoolDetailsTile';
import PoolPaymentsTile from './PoolPaymentsTile';
import PoolCyclesTile from './PoolCyclesTile';
import PoolNotesTile from './PoolNotesTile';
import PoolMembersTile from './PoolMembersTile';

const pageLinks = [
	{ name: 'Pools', path: '/pools', isCurrent: false },
	{ name: 'Pool Details', path: '/pools/details', isCurrent: true },
];

const actionsParams = {
	close: {
		path: '/closePool',
		title: 'Close Pool',
		subtitle: 'Pool has been closed!',
	},
	remove: {
		path: '/removeMember',
		title: 'Remove Member',
		subtitle: 'Pool member has been removed!',
	},
	addNotes: {
		path: '/addPoolNotes',
		title: 'Add Notes',
		subtitle: 'Pool notes has been added!',
	},
};

const ActionNotifications = ({ kind, subtitle, title, caption }) => (
	<Transition timeout={0.2}>
		<Row className="pool-detail__r2">
			<Column lg={6}>
				<InlineNotification
					kind={kind}
					iconDescription="describes the close button"
					subtitle={<span>{subtitle}</span>}
					title={title}
					className="pool-detail__notify"
				/>
			</Column>
		</Row>
	</Transition>
);

const ActionsButtons = ({ closePool, poolId }) => {
	const [modal, setModal] = React.useState(false);

	return (
		<>
			<Modal
				open={modal}
				modalHeading="Close pool"
				modalLabel="Pool management"
				primaryButtonText="Close pool"
				secondaryButtonText="Cancel"
				onRequestClose={() => setModal(false)}
				onRequestSubmit={() => {
					closePool();
					setModal(false);
				}}
				danger
				size="xs"
			>
				<p>
					Are you sure you want to close pool <strong>{poolId} </strong>?
				</p>
			</Modal>
			<ButtonSet>
				<Button
					size="field"
					className="pool-detail__button"
					onClick={() => setModal(true)}
					renderIcon={Close}
					kind="danger"
					iconDescription="Close this pool"
				>
					Close
				</Button>
			</ButtonSet>
		</>
	);
};

const PoolDetailsPage = ({ location }) => {
	const [{ token }] = React.useContext(AuthContext);
	const { pool } = location.state;
	const [poolId] = React.useState(pool.poolId);
	const [poolStatus, setPoolStatus] = React.useState(pool.status);
	const [members, setMembers] = React.useState({ rowLength: 0, rows: [] });
	const [notify, setNotify] = React.useState({ title: '', subtitle: '', kind: 'info' });
	// const [creator, setCreator] = React.useState('');
	// const [memberNames, setMemberNames] = React.useState({});

	const resetNotification = () => {
		setTimeout(() => {
			setNotify({ title: '', subtitle: '', kind: 'info' });
		}, 10000);
	};

	const poolActions = (action, data) => {
		let { path, title, subtitle } = actionsParams[action];
		let req = { token, poolId, data };
		if (data) req.data = data;
		adminService
			.post(path, req)
			.then((data) => {
				if (data.ok) {
					setNotify({ title, subtitle, kind: 'success' });
				} else {
					setNotify({ title, subtitle: data.message, kind: 'error' });
				}
				resetNotification();
			})
			.catch((e) => {
				setNotify({ title, subtitle: e.type, caption: e.message, kind: 'error' });
				resetNotification();
			});
	};

	// confirm pool closure with pop up
	const removeMember = ({ index, data }) => {
		poolActions('remove', data);
		const rows = members.rows;
		const rowLength = members.rowLength - 1;
		rows.splice(index, 1);
		setMembers({
			rowLength,
			rows,
			pageState: null,
		});
	};

	return (
		<ContentContainer>
			<ContentGrid>
				<Breadcrumbs links={pageLinks} />
				<Row className="pool-detail__r1">
					<Column lg={14}>
						<h1>{poolId}</h1>
						<Row className="pool-detail__status_r1">
							<Column lg={4} className="pool-detail__c3">
								<span className="pool-detail__status">
									<PoolType value={pool.type} />
								</span>
								<span className="pool-detail__status">
									<PoolStatus value={poolStatus} />
								</span>
							</Column>
							<Column lg={5}>
								<ActionsButtons
									poolId={poolId}
									closePool={() => {
										console.log('pool closed');
										poolActions('close');
										setPoolStatus(5);
									}}
								/>
							</Column>
						</Row>
					</Column>
				</Row>
				{notify.title !== '' && <ActionNotifications {...notify} />}

				<Row className="pool-detail__r3">
					<Column lg={16}>
						<Tabs light={true}>
							<Tab id="pool-details" label="Details">
								<Row>
									<Column lg={8} className="pool-detail__c3">
										<PoolDetailsTile pool={pool} />
									</Column>
									<Column lg={8} className="pool-detail__c3">
										<PoolMembersTile
											pool={pool}
											poolId={poolId}
											onRemove={({ index, data }) => removeMember({ index, data })}
										/>
									</Column>
								</Row>
							</Tab>

							<Tab id="pool-payments" label="Payments">
								<Row>
									<Column lg={16} className="pool-detail__c3">
										<PoolPaymentsTile poolId={poolId} />
									</Column>
								</Row>
							</Tab>
							<Tab id="pool-cycles" label="Cycles">
								<Row>
									<Column lg={16} className="pool-detail__c3">
										<PoolCyclesTile poolId={poolId} />
									</Column>
								</Row>
							</Tab>
							<Tab id="pool-notes" label="Notes">
								<Row>
									<Column lg={16} className="pool-detail__c3">
										<PoolNotesTile pool={pool} onSave={(data) => poolActions('addNotes', data)} />
									</Column>
								</Row>
							</Tab>
						</Tabs>
					</Column>
				</Row>
			</ContentGrid>
		</ContentContainer>
	);
};

export default PoolDetailsPage;
