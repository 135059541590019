import React from 'react';

import {
	Tile,
	StructuredListWrapper,
	StructuredListBody,
	StructuredListCell,
	Link,
	StructuredListRow,
} from 'carbon-components-react';

const TutorialsTile = ({ items }) => {
	const _renderRows = () => {
		return items.map((item, index) => (
			<StructuredListRow key={index} tabIndex={0}>
				<StructuredListCell>
					<Link>{item.link}</Link>
				</StructuredListCell>
				<StructuredListCell>{item.description}</StructuredListCell>
			</StructuredListRow>
		));
	};

	return (
		<Tile className="home-page__tile-r2">
			<h3 className="home-page__tile-header-2">Tutorials</h3>
			<div className="home-page__tutorial-body">
				<StructuredListWrapper ariaLabel="Structured list">
					<StructuredListBody>{_renderRows()}</StructuredListBody>
				</StructuredListWrapper>
			</div>
		</Tile>
	);
};

export default TutorialsTile;
