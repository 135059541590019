import React from "react";

import { Content, Loading } from "carbon-components-react";
import { Route, Redirect, Switch } from "react-router-dom";

import { AuthContext, DataProvider } from "./store";

import BaseHeader from "./components/BaseHeader";
import MainHeader from "./components/MainHeader";
import LoginPage from "./content/LoginPage";
import HomePage from "./content/HomePage";
import DashboardPage from "./content/DashboardPage";
import ServicesPage from "./content/ServicesPage";
import { PoolsPage, PoolDetailsPage } from "./content/PoolsPage";
import { UsersPage, UserDetailsPage } from "./content/UsersPage";
import { urlPath } from "./utils";

const redirectRoute = (Component, isAuthenticated, checkFlag, props, path) =>
  isAuthenticated === checkFlag ? (
    <Component {...props} />
  ) : (
    <Redirect
      to={{ pathname: urlPath(path), state: { from: props.location } }}
    />
  );

const PrivateRoute = ({ component: Component, ...rest }) => {
  const [{ isAuthenticated }] = React.useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={(props) =>
        redirectRoute(Component, isAuthenticated, true, props, "/login")
      }
    />
  );
};

const PublicRoute = ({ component: Component, ...rest }) => {
  const [{ isAuthenticated }] = React.useContext(AuthContext);
  return (
    <Route
      {...rest}
      render={(props) =>
        redirectRoute(Component, isAuthenticated, false, props, "/")
      }
    />
  );
};

const LoadingRoute = () => {
  return (
    <Route>
      <BaseHeader />
      <Content>
        <Loading className="main__page_loader" />
      </Content>
    </Route>
  );
};

const HeaderRoute = ({ path }) =>
  path === urlPath("/login") ? <BaseHeader /> : <MainHeader path={path} />;

const ContentRoute = ({ path }) => {
  return (
    <DataProvider>
      <HeaderRoute path={path} />
      <Content>
        <Switch>
          <PrivateRoute exact path={urlPath("/")} component={HomePage} />
          <PrivateRoute
            exact
            path={urlPath("/dashboard")}
            component={DashboardPage}
          />
          <PrivateRoute
            exact
            path={urlPath("/services")}
            component={ServicesPage}
          />
          {/* <PrivateRoute exact path="/payments" component={PaymentsPage} /> */}
          <PrivateRoute exact path={urlPath("/pools")} component={PoolsPage} />
          <PrivateRoute
            exact
            path={urlPath("/pools/details")}
            component={PoolDetailsPage}
          />
          <PrivateRoute exact path={urlPath("/users")} component={UsersPage} />
          <PrivateRoute
            exact
            path={urlPath("/users/details")}
            component={UserDetailsPage}
          />
          <PublicRoute exact path={urlPath("/login")} component={LoginPage} />
        </Switch>
      </Content>
    </DataProvider>
  );
};
export { LoadingRoute, ContentRoute };
