import React from 'react';
import { Tile, Tag, Modal } from 'carbon-components-react';

const PoolMembersTile = ({ pool, poolId, onRemove }) => {
	const [modal, setModal] = React.useState(false);
	const [memberId, setMemberId] = React.useState('');
	const [name, setName] = React.useState('');
	const [index, setIndex] = React.useState(-1);

	const closeModal = () => {
		setModal(false);
		setMemberId('');
		setName('');
	};

	return (
		<Tile className="pool-detail__tile_profile">
			<h3 className="pool-detail__tile_header">Pool Members</h3>
			<div className="pool-detail__members">
				{pool.members.map(
					(row, i) =>
						!row.removed && (
							<Tag
								filter
								key={row.user.userId}
								onClose={() => {
									setMemberId(row.user.userId);
									setName(row.user.displayName);
									setIndex(i);
									setModal(true);
								}}
							>
								{row.user.displayName}
							</Tag>
						)
				)}
			</div>
			<Modal
				open={modal}
				modalHeading="Remove pool member"
				modalLabel="Pool management"
				primaryButtonText="Remove member"
				secondaryButtonText="Cancel"
				onRequestClose={closeModal}
				onRequestSubmit={() => {
					onRemove({ index, data: { memberId } });
					closeModal();
				}}
				danger
				size="xs"
			>
				<p>
					Are you sure you want to remove <strong>{name}</strong> from pool <strong>{poolId} </strong>?
				</p>
			</Modal>
		</Tile>
	);
};

export default PoolMembersTile;
