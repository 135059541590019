import React from "react";

import { Row, Column, Tile } from "carbon-components-react";

import {
  Partnership20 as Partnership,
  Group20 as Group,
  Money20 as Money,
  Wallet20 as Wallet,
  AppConnectivity20 as AppConnectivity,
} from "@carbon/icons-react";

import {
  ContentGrid,
  ContentContainer,
} from "../../components/ContentContainer";
import Breadcrumbs from "../../components/Breadcrumbs";

import { formatBigCurrency } from "../../utils";
import { DataContext, AuthContext, fetchData } from "../../store";
import { ServicesTableMinimal } from "../../components/ServicesTable";

import LineGraph from "./LineGraph";
import BarGraph from "./BarGraph";
import DonutGraph from "./DonutGraph";

const pageLinks = [{ name: "Dashboard", path: "/dashboard", isCurrent: false }];
const headers = [
  { key: "name", header: "Service Name" },
  { key: "state", header: "Status" },
];

const fetchDonutValues = (data) => {
  if (data === null || typeof data === "undefined") {
    return [
      { group: "Susu", value: 0 },
      { group: "Single", value: 0 },
    ];
  }

  return [
    { group: "Susu", value: parseInt(data.susuPools ? data.susuPools : 0) },
    {
      group: "Single",
      value: parseInt(data.singlePools ? data.singlePools : 0),
    },
  ];
};

const DashboardPage = () => {
  const [{ token }] = React.useContext(AuthContext);
  const [{ dashboard, services }, dispatch] = React.useContext(DataContext);

  const [dash, setDash] = React.useState(dashboard.rows[0]);
  const [donut, setDonut] = React.useState(fetchDonutValues(dash));

  React.useEffect(() => {
    const action = "FETCH_DASHBOARD";
    const timer = setInterval(
      () => fetchData({ dispatch, token, action }),
      30000
    );
    return () => clearInterval(timer);
  }, [dispatch, token]);

  React.useEffect(() => {
    const action = "FETCH_SERVICES";
    const timer = setInterval(
      () => fetchData({ dispatch, token, action }),
      30000
    );
    return () => clearInterval(timer);
  }, [dispatch, token]);

  React.useEffect(() => {
    setDash(dashboard.rows[0]);
  }, [dashboard]);

  React.useEffect(() => {
    setDonut(fetchDonutValues(dash));
  }, [dash]);

  return (
    <ContentContainer>
      <ContentGrid>
        <Breadcrumbs links={pageLinks} />
        <h1>Dashboard</h1>
        <Row className="dashboard-page__r1">
          <Column lg={12}>
            <Row>
              <Column lg={8}>
                <Row className="dashboard-page__rs">
                  <Column lg={8}>
                    <Tile className="dashboard-page__tile_2">
                      <h5 className="dashboard-page__h2">Users Added</h5>
                      <h5 className="dashboard-page__h2-sub">Current</h5>
                      <h1 className="dashboard-page__h3">
                        {dash.usersAdded ? dash.usersAdded : ""}
                      </h1>
                      <Group className="dashboard-page__tile_icon" />
                    </Tile>
                  </Column>
                  <Column lg={8}>
                    <Tile className="dashboard-page__tile_2">
                      <h5 className="dashboard-page__h2">Pools Created</h5>
                      <h5 className="dashboard-page__h2-sub">Current</h5>
                      <h1 className="dashboard-page__h3">
                        {dash.poolsCreated ? dash.poolsCreated : ""}
                      </h1>
                      <Partnership className="dashboard-page__tile_icon" />
                    </Tile>
                  </Column>
                </Row>
                <Row>
                  <Column lg={8}>
                    <Tile className="dashboard-page__tile_2">
                      <h5 className="dashboard-page__h2">Pool Deposits</h5>
                      <h5 className="dashboard-page__h2-sub">To Date </h5>
                      <span className="dashboard-page__h3_span">
                        <h1 className="dashboard-page__h3">
                          {formatBigCurrency(
                            dash.depositsVolume ? dash.depositsVolume : 0
                          )}
                        </h1>
                        <h1 className="dashboard-page__h3_unit">GHC </h1>
                      </span>
                      <Wallet className="dashboard-page__tile_icon" />
                    </Tile>
                  </Column>
                  <Column lg={8}>
                    <Tile className="dashboard-page__tile_2">
                      <h5 className="dashboard-page__h2">Pool Payouts</h5>
                      <h5 className="dashboard-page__h2-sub">To Date</h5>
                      <span className="dashboard-page__h3_span">
                        <h1 className="dashboard-page__h3">
                          {formatBigCurrency(
                            dash.payoutsVolume ? dash.payoutsVolume : 0
                          )}
                        </h1>
                        <h1 className="dashboard-page__h3_unit">GHC </h1>
                      </span>
                      <Money className="dashboard-page__tile_icon" />
                    </Tile>
                  </Column>
                </Row>
              </Column>
              <Column lg={8}>
                <Tile className="dashboard-page__chart">
                  <DonutGraph data={donut} />
                </Tile>
              </Column>
            </Row>
            <Row className="dashboard-page__r2">
              <Column lg={8}>
                <Tile className="dashboard-page__chart">
                  <LineGraph />
                </Tile>
              </Column>
              <Column lg={8}>
                <Tile className="dashboard-page__chart">
                  <BarGraph />
                </Tile>
              </Column>
            </Row>
          </Column>
          <Column lg={4}>
            <Tile className="dashboard-page__tile_1">
              <h5 className="dashboard-page__h2">Application Services</h5>
              <AppConnectivity className="dashboard-page__tile_icon" />
              <div className="services__table_min-content">
                <ServicesTableMinimal headers={headers} data={services.rows} />
              </div>
            </Tile>
          </Column>
        </Row>
      </ContentGrid>
    </ContentContainer>
  );
};

export default DashboardPage;
