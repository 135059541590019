import React from "react";
import {
  Tile,
  Button,
  InlineLoading,
  TextArea,
  StructuredListWrapper,
  StructuredListBody,
  StructuredListHead,
  StructuredListRow,
  StructuredListCell,
} from "carbon-components-react";

import {
  AddAlt20 as Add,
  Save20 as Save,
  Close20 as Close,
} from "@carbon/icons-react";

import { AuthContext } from "../../store";
import { adminService } from "../../services";
import { formatGMTDate } from "../../utils";

const PoolNotesView = (props) => (
  <>
    <Button
      size="sm"
      className="pool-detail__button_icon"
      kind="ghost"
      role="button"
      onClick={props.onEdit}
    >
      <Add className="pool-detail__icon-actions" description="Add address" />
    </Button>
    {props.loading && <InlineLoading description="Loading..." />}
    {!props.loading && (
      <>
        {props.failed && (
          <InlineLoading description="Loading error" status="error" />
        )}
        {!props.failed && (
          <>
            {props.notes.rowLength > 0 && (
              <div className="pool-detail__notes_box">
                <StructuredListWrapper>
                  <StructuredListHead>
                    <StructuredListRow head tabIndex={0}>
                      <StructuredListCell key="head:id" head>
                        Administrator ID
                      </StructuredListCell>
                      <StructuredListCell key="head:notes" head>
                        Notes
                      </StructuredListCell>
                      <StructuredListCell key="head:date" head>
                        Date
                      </StructuredListCell>
                    </StructuredListRow>
                  </StructuredListHead>
                  <StructuredListBody>
                    {props.notes.rows.map((row, i) => (
                      <React.Fragment key={i}>
                        <StructuredListRow key={i} tabIndex={0}>
                          <StructuredListCell key="id">
                            {row.adminId.slice(0, 8)}...
                          </StructuredListCell>
                          <StructuredListCell key="note">
                            {row.notes}
                          </StructuredListCell>
                          <StructuredListCell
                            key="date"
                            className="data-table-cell--date-time"
                          >
                            {formatGMTDate(row.createdAt)}
                          </StructuredListCell>
                        </StructuredListRow>
                      </React.Fragment>
                    ))}
                  </StructuredListBody>
                </StructuredListWrapper>
              </div>
            )}
            {props.notes.rowLength === 0 && (
              <p className="pool-detail__no_items">
                There are no notes for this pool!
              </p>
            )}
          </>
        )}
      </>
    )}
  </>
);

const PoolNotesEdit = (props) => (
  <>
    <div className="pool-detail__button_icon">
      <Button
        size="sm"
        onClick={props.onSubmit}
        role="button"
        kind="ghost"
        className="pool-detail__save-button"
      >
        <Save
          className="pool-detail__icon-actions"
          description="Save changes"
        />
      </Button>
      <Button
        size="sm"
        onClick={props.onCancel}
        role="button"
        kind="ghost"
        className="pool-detail__save-button"
      >
        <Close
          className="pool-detail__icon-actions"
          description="Cancel changes"
        />
      </Button>
    </div>
    <TextArea
      light
      rows={9}
      value={props.text}
      onChange={(e) => props.setText(e.target.value)}
    />
  </>
);

const PoolNotesTile = ({ pool, onSave }) => {
  const [{ token }] = React.useContext(AuthContext);

  const [poolId] = React.useState(pool.poolId);

  const [notes, setNotes] = React.useState({ rowLength: 0 });
  const [status, setStatus] = React.useState({ failed: false, loaded: false });

  const [mode, setMode] = React.useState("view");
  const [text, setText] = React.useState("");

  React.useEffect(() => {
    const loadNotes = async () => {
      adminService
        .post("/fetchPoolNotes", { token, poolId })
        .then((data) => {
          if (data.ok) {
            setNotes(data);
            setStatus({ failed: false, loading: false });
          } else {
            setStatus({ failed: true, loading: false });
          }
        })
        .catch((e) => {
          setStatus({ failed: true, loading: false });
        });
    };
    loadNotes();
  }, [token, poolId]);

  const onCancel = () => {
    setMode("view");
  };

  const onEdit = () => {
    setMode("edit");
  };

  const onSubmit = () => {
    onSave({ notes: text });
    setText("");
    setMode("view");
  };

  return (
    <Tile className="pool-detail__tile_notes">
      <h3 className="pool-detail__tile_header">Notes</h3>
      {mode === "view" && (
        <PoolNotesView {...status} notes={notes} onEdit={onEdit} />
      )}
      {mode === "edit" && (
        <PoolNotesEdit
          onSubmit={onSubmit}
          onCancel={onCancel}
          text={text}
          setText={setText}
        />
      )}
    </Tile>
  );
};

export default PoolNotesTile;
