const data = {
	tutorials: [
		{
			link: 'Upload user documents',
			description: 'Learn how to upload user verification documents.',
		},
		{
			link: 'Credit pool wallet',
			description: 'Learn how to deposit money from a user to a pool.',
		},
		{
			link: 'Debit pool wallet',
			description: 'Learn how to withdraw funds from pool to users.',
		},
	],
	announcements: [
		'Company wide meeting on January 11,2021',
		'Service testing underway. Latest version to be deployed soon',
		'Payment service. Resolution ETA 2 hours',
	],
};

export default data;
