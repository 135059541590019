import React from "react";
import { Row, Column, Tile, Button, TextInput } from "carbon-components-react";

import {
  Edit20 as Edit,
  Save20 as Save,
  Close20 as Close,
} from "@carbon/icons-react";

import { formatCurrency } from "../../utils";

import LabeledData from "../../components/LabeledData";

const EmploymentView = (props) => (
  <>
    <Button
      size="sm"
      className="user-detail__button_icon"
      kind="ghost"
      role="button"
      onClick={props.onEdit}
    >
      <Edit className="user-detail__icon-actions" description="Edit profile" />
    </Button>
    <Row className="user-detail__row_data">
      <Column lg={8}>
        <LabeledData labelText="Occupation" value={props.occupation} />
      </Column>
      <Column lg={8}>
        <LabeledData labelText="Employer Name" value={props.employerName} />
      </Column>
    </Row>
    <Row className="user-detail__row_data">
      <Column lg={8}>
        <LabeledData
          labelText="Salary Estimate"
          value={formatCurrency(props.salary)}
        />
      </Column>
    </Row>
  </>
);

const EmploymentEdit = (props) => (
  <>
    <div className="user-detail__button_icon">
      <Button
        size="sm"
        onClick={props.onSubmit}
        role="button"
        kind="ghost"
        className="user-detail__save-button"
      >
        <Save
          className="user-detail__icon-actions"
          description="Save changes"
        />
      </Button>
      <Button
        size="sm"
        onClick={props.onCancel}
        role="button"
        kind="ghost"
        className="user-detail__save-button"
      >
        <Close
          className="user-detail__icon-actions"
          description="Cancel changes"
        />
      </Button>
    </div>
    <Row className="user-detail__row_data">
      <Column lg={8}>
        <TextInput
          light
          labelText="Occupation"
          name="occupation"
          value={props.occupation}
          onChange={props.handleInput}
        />
      </Column>
      <Column lg={8}>
        <TextInput
          light
          labelText="Employer Name"
          name="employerName"
          value={props.employerName}
          onChange={props.handleInput}
        />
      </Column>
    </Row>
    <Row className="user-detail__row_data">
      <Column lg={8}>
        <TextInput
          light
          name="salary"
          labelText="Salary Estimate"
          value={props.salary}
          onChange={props.handleInput}
        />
      </Column>
    </Row>
  </>
);

const UserEmploymentTile = ({ user, onSave }) => {
  const [mode, setMode] = React.useState("view");
  const [state, setState] = React.useState(
    user.employment ? { ...user.employments[0] } : {}
  );

  const onCancel = () => {
    setState({ ...user.employments[0] });
    setMode("view");
  };

  const onEdit = () => {
    setMode("edit");
  };

  const onSubmit = () => {
    onSave(state);
    setMode("view");
  };

  const handleInput = (e) => {
    const { target } = e;
    const { value, name } = target;
    setState({ ...state, [name]: value });
  };

  return (
    <Tile className="user-detail__tile_profile">
      <h3 className="user-detail__tile_header">Employment</h3>
      {mode === "view" && <EmploymentView {...state} onEdit={onEdit} />}
      {mode === "edit" && (
        <EmploymentEdit
          {...state}
          onSubmit={onSubmit}
          onCancel={onCancel}
          handleInput={handleInput}
        />
      )}
    </Tile>
  );
};

export default UserEmploymentTile;
