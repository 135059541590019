import React from 'react';
import { Tag } from 'carbon-components-react';

const Type = {
	0: (
		<Tag type="gray" className="susu__type_tag">
			unknown
		</Tag>
	),
	1: (
		<Tag type="high-contrast" className="susu__type_tag">
			susu
		</Tag>
	),
	2: (
		<Tag type="warm-gray" className="susu__type_tag">
			single
		</Tag>
	),
};

const PoolType = ({ value }) => {
	return Type[value];
};

export default PoolType;
