import React from 'react';

import { adminService } from '../services';

const FETCH_USERS = 'FETCH_USERS';
const FETCH_POOLS = 'FETCH_POOLS';
// const FETCH_PAYMENTS = 'FETCH_PAYMENTS';
const FETCH_SERVICES = 'FETCH_SERVICES';
const FETCH_DASHBOARD = 'FETCH_DASHBOARD';

const dataActions = {
	FETCH_USERS: '/fetchUsers',
	FETCH_POOLS: '/fetchPools',
	FETCH_SERVICES: '/fetchServices',
	FETCH_DASHBOARD: '/fetchDashboard',
};

const actions = {
	FETCH_USERS,
	FETCH_POOLS,
	FETCH_SERVICES,
	FETCH_DASHBOARD,
};

const initialState = {
	dashboard: { loaded: false, failed: false, rows: [], rowLength: 0 },
	users: { loaded: false, failed: false, rows: [], rowLength: 0 },
	pools: { loaded: false, failed: false, rows: [], rowLength: 0 },
	services: { loaded: false, failed: false, rows: [], rowLength: 0 },
	updatesId: null,
};

const reducer = (state, action) => {
	switch (action.type) {
		case actions.FETCH_DASHBOARD:
			return { ...state, dashboard: action.payload };
		case actions.FETCH_USERS:
			return { ...state, users: action.payload };
		case actions.FETCH_POOLS:
			return { ...state, pools: action.payload };
		case actions.FETCH_SERVICES:
			return { ...state, services: action.payload };
		case actions.SET_UPDATES:
			return { ...state, updatesId: action.payload.updatesId };
		case actions.STOP_UPDATES:
			return { ...state, updatesId: null };
		default:
			return state;
	}
};

const dispatchError = ({ action, dispatch, error }) => {
	console.log('Error :: ', error);
	dispatch({
		type: action,
		payload: { loaded: false, failed: true, rows: [], rowLength: 0 },
	});
};

const fetchData = ({ dispatch, action, token }) => {
	adminService
		.post(dataActions[action], { token })
		.then((data) => {
			if (!data.ok) {
				dispatchError({ action, dispatch, error: data });
				return;
			}
			dispatch({
				type: action,
				payload: { loaded: true, failed: false, ...data },
			});
		})
		.catch((e) => {
			dispatchError({ action, dispatch, error: e });
		});
};

const fetchAll = ({ dispatch, token }) => {
	const actions = [FETCH_DASHBOARD, FETCH_SERVICES, FETCH_POOLS, FETCH_USERS];
	for (let action of actions) {
		fetchData({ dispatch, token, action });
	}
	console.log('data loaded');
};

const DataContext = React.createContext(initialState);
const DataProvider = ({ children }) => {
	const [state, dispatch] = React.useReducer(reducer, initialState);
	return <DataContext.Provider value={[state, dispatch]}>{children}</DataContext.Provider>;
};

export { DataContext, DataProvider, fetchAll, fetchData };
