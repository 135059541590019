import React from "react";

import {
  TableContainer,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
  Table,
  DataTable,
  TableHead,
  TableRow,
  // TableExpandHeader,
  TableHeader,
  TableBody,
  TableCell,
} from "carbon-components-react";

import { CellValue } from "../../components/CellValue";

const GenericTable = ({
  title,
  description,
  data,
  headers,
  size,
  className,
}) => {
  const [rows, setRows] = React.useState(data);

  React.useEffect(() => {
    setRows(data);
  }, [data]);

  return (
    <DataTable rows={rows} headers={headers}>
      {({
        rows,
        headers,
        getHeaderProps,
        getRowProps,
        getToolbarProps,
        getBatchActionProps,
        onInputChange,
        getTableProps,
        getTableContainerProps,
      }) => (
        <TableContainer
          className={className ? className : ""}
          title={title ? title : ""}
          description={description ? description : ""}
          {...getTableContainerProps()}
        >
          <TableToolbar {...getToolbarProps()}>
            <TableToolbarContent>
              <TableToolbarSearch
                defaultExpanded
                tabIndex={getBatchActionProps().shouldShowBatchActions ? -1 : 0}
                onChange={onInputChange}
              />
            </TableToolbarContent>
          </TableToolbar>
          <Table {...getTableProps()} size={size ? size : "normal"}>
            <TableHead>
              <TableRow>
                {/* <TableExpandHeader /> */}
                {headers.map((header, i) => (
                  <TableHeader key={i} {...getHeaderProps({ header })}>
                    {header.header}
                  </TableHeader>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, i) => (
                <React.Fragment key={row.id}>
                  <TableRow {...getRowProps({ row })}>
                    {row.cells.map((cell) => (
                      <TableCell>
                        <CellValue key={cell.id} {...cell} />
                      </TableCell>
                    ))}
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </DataTable>
  );
};

export default GenericTable;
