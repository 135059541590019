import React from "react";

import { Link } from "react-router-dom";
import {
  CloseFilled16 as CloseFilled,
  CheckmarkFilled16 as CheckmarkFilled,
} from "@carbon/icons-react";

import {
  formatGMTDate,
  formatCurrency,
  formatDate,
  urlPath,
} from "../../utils";

import PoolStatus from "../../components/PoolStatus";
import PoolType from "../../components/PoolType";
import PaymentType from "../../components/PaymentType";
import PaymentStatus from "../../components/PaymentStatus";
import ServiceStatus from "../../components/ServiceStatus";
import ServiceGroup from "../../components/ServiceGroup";
import { CodeSnippet } from "carbon-components-react";

const splitUptime = (name) => {
  let data = name.split("uptime");
  let last = data[1];
  if (!last) return "";
  return last;
};

const VALUES = {
  dateOfBirth: (value) => (value ? formatDate(value) : ""),
  startDate: (value) => (value ? formatDate(value) : ""),
  endDate: (value) => (value ? formatDate(value) : ""),
  paymentDate: (value) => (value ? formatDate(value) : ""),
  mandateDate: (value) => (value ? formatDate(value) : ""),
  createdAt: (value) =>
    value ? (
      <p className="data-table-cell--date-time">{formatGMTDate(value)} </p>
    ) : (
      ""
    ),
  startedAt: (value) =>
    value ? (
      <p className="data-table-cell--date-time">{formatGMTDate(value)} </p>
    ) : (
      ""
    ),
  updatedAt: (value) =>
    value ? (
      <p className="data-table-cell--date-time">{formatGMTDate(value)} </p>
    ) : (
      ""
    ),
  start: (value) =>
    value ? (
      <p className="data-table-cell--date-time">{formatGMTDate(value)} </p>
    ) : (
      ""
    ),
  now: (value) =>
    value ? (
      <p className="data-table-cell--date-time">{formatGMTDate(value)} </p>
    ) : (
      ""
    ),
  depositAmount: (value) =>
    !value || value === "0" ? "" : formatCurrency(value),
  paymentAmount: (value) =>
    !value || value === "0" ? "" : formatCurrency(value),
  balance: (value) => (value ? formatCurrency(value) : ""),
  depositRate: (value) => (value ? `${value} days` : ""),
  payoutRate: (value) => (value ? `${value} days` : ""),
  poolId: (value) => (value ? value : ""),
  id: (value) => (value ? `${value.slice(0, 8)}...` : ""),
  creator: (value) => (value ? value.displayName : ""),
  userId: (value) => (value ? `${value.slice(0, 8)}...` : ""),
  paymentId: (value) => (value ? `${value.slice(0, 8)}...` : ""),
  mandateId: (value) => (value ? `${value.slice(0, 8)}...` : ""),
  email: (value) => (value ? value : ""),
  emails: (value) => (value ? value[0].email : ""),
  name: (value) => (value ? value : ""),
  firstName: (value) => (value ? value : ""),
  user: (value) => (value ? value.displayName : ""),
  displayName: (value) => (value ? value : ""),
  lastName: (value) => (value ? value : ""),
  employments: (value) => (value ? value[0].occupation : ""),
  occupation: (value) => (value ? value : ""),
  paymentReference: (value) => (value ? value : ""),
  mandateReference: (value) => (value ? value : ""),
  employerName: (value) => (value ? value : ""),
  description: (value) => splitUptime(value),
  pid: (value) => (value > 0 ? value : ""),
  group: (value) => <ServiceGroup value={value} />,
  state: (value) => <ServiceStatus value={value} />,
  type: (value) => <PoolType value={value} />,
  image: (value) => <CodeSnippet type="inline">{value}</CodeSnippet>,
  tags: (value) => (value ? value : ""),
  shortId: (value) => <CodeSnippet type="inline">{`${value}...`}</CodeSnippet>,
  status: (value) => <PoolStatus value={value} />,
  paymentStatus: (value) => <PaymentStatus value={value} />,
  mandateStatus: (value) => <PaymentStatus value={value} />,
  paymentType: (value) => <PaymentType value={value} />,
  createdAgo: (value) => (value ? value : ""),
  startedAgo: (value) => (value ? value : ""),
  verified: (value) =>
    value === true ? (
      <CheckmarkFilled className="users-page__icon-green" />
    ) : (
      <CloseFilled className="users-page__icon-red" />
    ),
  blocked: (value) =>
    value === true ? (
      <CheckmarkFilled className="users-page__icon-green" />
    ) : (
      <CloseFilled className="users-page__icon-red" />
    ),
  completed: (value) =>
    value === true ? (
      <CheckmarkFilled className="users-page__icon-green" />
    ) : (
      <CloseFilled className="users-page__icon-red" />
    ),
  closed: (value) =>
    value === true ? (
      <CheckmarkFilled className="users-page__icon-green" />
    ) : (
      <CloseFilled className="users-page__icon-red" />
    ),

  phoneNumber: (value) => (value ? value : ""),
  phoneNumbers: (value) => (value ? value[0].phoneNumber : ""),
};

const LINKS = {
  poolId: (value, data) => (
    <p className="data-table-cell--id">
      <Link to={{ pathname: urlPath("/pools/details"), state: { pool: data } }}>
        {VALUES.poolId(value)}
      </Link>
    </p>
  ),
  userId: (value, data) => (
    <p className="data-table-cell--id">
      <Link to={{ pathname: urlPath("/users/details"), state: { user: data } }}>
        {VALUES.userId(value)}
      </Link>
    </p>
  ),
};
const CellValue = ({ id, value }) => {
  const key = id.split(":")[1];
  try {
    const filter = VALUES[key];
    if (!filter) return "null";
    return filter(value);
  } catch (err) {
    return "null";
  }
};

const CellLink = ({ id, value, data }) => {
  const key = id.split(":")[1];
  try {
    const filter = LINKS[key];
    if (!filter) return "null";
    return filter(value, data);
  } catch (err) {
    return "null";
  }
};
export { CellValue, CellLink };
