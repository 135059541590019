import React from 'react';

import { Grid, Row, Column } from 'carbon-components-react';

const ContentGrid = ({ children }) => {
	return (
		<Grid>
			<Row>
				<Column lg={16}>{children}</Column>
			</Row>
		</Grid>
	);
};

export default ContentGrid;
