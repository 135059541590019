import React from 'react';
import { Row, Column, Tile } from 'carbon-components-react';

import { formatDate, formatCurrency } from '../../utils';

import LabeledData from '../../components/LabeledData';

const PoolDetailsTile = ({ pool }) => {
	return (
		<Tile className="pool-detail__tile_profile">
			<h3 className="pool-detail__tile_header">Pool Details</h3>
			<Row className="pool-detail__row_data">
				<Column lg={8}>
					<LabeledData labelText="Pool Creator" value={pool.creator.displayName} />
				</Column>
				<Column lg={8}>
					<LabeledData labelText="Start Date" value={pool.startDate ? formatDate(pool.startDate) : ''} />
				</Column>
			</Row>
			<Row className="pool-detail__row_data">
				<Column lg={8}>
					<LabeledData labelText="Pool Balance" value={formatCurrency(pool.balance)} />
				</Column>
				<Column lg={8}>
					<LabeledData labelText="Deposit Amount" value={formatCurrency(pool.depositAmount)} />
				</Column>
			</Row>
			<Row className="pool-detail__row_data">
				<Column lg={8}>
					<LabeledData labelText="Deposit Rate" value={pool.depositRate ? `${pool.depositRate} days` : ''} />
				</Column>
				<Column lg={8}>
					<LabeledData labelText="Payout Rate" value={pool.payoutRate ? `${pool.payoutRate} days` : ''} />
				</Column>
			</Row>
		</Tile>
	);
};

export default PoolDetailsTile;
