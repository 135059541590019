const formatCurrency = (value) =>
  new Intl.NumberFormat("en-GH", {
    style: "currency",
    currency: "GHC",
  }).format(value);

const formatBigCurrency = (value) => {
  const v = parseFloat(value);
  const len = parseInt(value).toString().length;
  if (len <= 3) return `${v.toFixed(2)}`;
  if (len > 3 && len <= 6) return `${(v / 1e3).toFixed(2)}K`;
  if (len > 6 && len <= 9) return `${(v / 1e6).toFixed(2)}M`;
  if (len > 9) return `${(v / 1e9).toFixed(2)}B`;
};

const formatDate = (value) => {
  const d = new Date(value);
  return d.toDateString();
};

const formatGMTDate = (dateString) => {
  const d = new Date(dateString);
  return d.toGMTString();
};

const formatISODate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

let formatPhoneNumber = (str) => {
  //Filter only numbers from the input
  let cleaned = ("" + str).replace(/\D/g, "");

  //Check if the input is of correct
  let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    //Remove the matched extension code
    //Change this to format for any country code.
    let intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }

  return null;
};
export {
  formatCurrency,
  formatBigCurrency,
  formatDate,
  formatGMTDate,
  formatISODate,
  formatPhoneNumber,
};
