import React from "react";
import {
  Header,
  HeaderContainer,
  HeaderName,
  // HeaderNavigation,
  HeaderMenuButton,
  HeaderMenuItem,
  SkipToContent,
  SideNav,
  SideNavItems,
  HeaderSideNavItems,
} from "carbon-components-react";

import { Link } from "react-router-dom";
import { urlPath } from "../../utils";

const BaseHeader = () => (
  <HeaderContainer
    render={({ onClickSideNavExpand }) => (
      <Header aria-label="Carbon Tutorial">
        <SkipToContent />
        <HeaderMenuButton
          aria-label="Open menu"
          onClick={onClickSideNavExpand}
        />
        <HeaderName element={Link} to={urlPath("/")} prefix="SUSU">
          SUSUPOND
        </HeaderName>
        <SideNav aria-label="Side navigation" isPersistent={false}>
          <SideNavItems>
            <HeaderSideNavItems>
              <HeaderMenuItem element={Link} to={urlPath("/")}>
                Dashboard
              </HeaderMenuItem>
            </HeaderSideNavItems>
          </SideNavItems>
        </SideNav>
      </Header>
    )}
  />
);

export default BaseHeader;
