import React from "react";
import { Transition } from "react-transition-group";
import {
  Row,
  Column,
  Button,
  FormLabel,
  ButtonSet,
  InlineNotification,
  Tabs,
  Tab,
} from "carbon-components-react";

import {
  CheckmarkOutline24 as Checkmark,
  ErrorOutline24 as Block,
  Unlocked24 as Unblock,
} from "@carbon/icons-react";

import { AuthContext } from "../../store";
import { adminService } from "../../services";

import {
  ContentGrid,
  ContentContainer,
} from "../../components/ContentContainer";
import CheckStatus from "../../components/CheckStatus";
import Breadcrumbs from "../../components/Breadcrumbs";
import UserProfileTile from "./UserProfileTile";
import UserPhoneNumberTile from "./UserPhoneNumberTile";
import UserEmailTile from "./UserEmailTile";
import UserAddressTile from "./UserAddressTile";
import UserEmploymentTile from "./UserEmploymentTile";
import UserDocumentsTile from "./UserDocumentsTile";
import UserPaymentsTile from "./UserPaymentsTile";
import UserMandatesTile from "./UserMandatesTile";
import UserPoolsTile from "./UserPoolsTile";
import UserNotesTile from "./UserNotesTile";

const pageLinks = [
  { name: "Users", path: "/users", isCurrent: false },
  { name: "User Details", path: "/users/details", isCurrent: true },
];

const actionsParams = {
  verify: {
    path: "/verifyUser",
    title: "Verify User",
    subtitle: "User has been verified!",
  },
  block: {
    path: "/blockUser",
    title: "Block User",
    subtitle: "User has been blocked!",
  },
  unblock: {
    path: "/unblockUser",
    title: "Unblock User",
    subtitle: "User has been unblocked!",
  },
  editProfile: {
    path: "/updateUserProfile",
    title: "Update profile",
    subtitle: "User profile has been updated!",
  },
  addPhone: {
    path: "/addUserPhoneNumber",
    title: "Update Phone Number",
    subtitle: "User phone number has been updated!",
  },
  addEmail: {
    path: "/addUserEmail",
    title: "Update Email",
    subtitle: "User email has been updated!",
  },
  addEmployment: {
    path: "/addUserEmployment",
    title: "Add Employment",
    subtitle: "User employment details have been added!",
  },
  addAddress: {
    path: "/addUserAddress",
    title: "Add Address",
    subtitle: "User address has been added!",
  },
  addDocument: {
    path: "/addUserDocument",
    title: "Add Document",
    subtitle: "User document has been added!",
  },
  addNotes: {
    path: "/addUserNote",
    title: "Add Notes",
    subtitle: "User notes has been added!",
  },
};

const ActionNotifications = ({ kind, subtitle, title, caption }) => (
  <Transition timeout={0.2}>
    <Row className="user-detail__r2">
      <Column lg={6}>
        <InlineNotification
          kind={kind}
          iconDescription="describes the close button"
          subtitle={<span>{subtitle}</span>}
          title={title}
          className="user-detail__notify"
          // caption=""
        />
      </Column>
    </Row>
  </Transition>
);

const ActionsButtons = ({ verifyUser, blockUser, unblockUser }) => {
  return (
    <ButtonSet>
      <Button
        size="field"
        className="user-detail__button"
        onClick={verifyUser}
        renderIcon={Checkmark}
        kind="primary"
      >
        Verify
      </Button>
      <Button
        size="field"
        className="user-detail__button"
        onClick={blockUser}
        renderIcon={Block}
        kind="danger"
      >
        Block
      </Button>
      <Button
        size="field"
        className="user-detail__button"
        onClick={unblockUser}
        renderIcon={Unblock}
        kind="secondary"
      >
        Unblock
      </Button>
    </ButtonSet>
  );
};

const UserDetailsPage = ({ location }) => {
  const [{ token }] = React.useContext(AuthContext);
  const { user } = location.state;
  const userId = user.userId;
  const [notify, setNotify] = React.useState({
    title: "",
    subtitle: "",
    kind: "info",
  });
  const [verified, setVerified] = React.useState(user.verified);
  const [blocked, setBlocked] = React.useState(user.blocked);
  const [userImage, setUserImage] = React.useState(
    "https://dummyimage.com/250x250"
  );

  const resetNotification = () => {
    setTimeout(() => {
      setNotify({ title: "", subtitle: "", kind: "info" });
    }, 10000);
  };

  const userActions = (action, data) => {
    let { path, title, subtitle } = actionsParams[action];
    let req = { token, userId, data };
    if (data) req.data = data;
    adminService
      .post(path, req)
      .then((data) => {
        if (data.ok) {
          setNotify({ title, subtitle, kind: "success" });
          if (action === "verify") setVerified(true);
          if (action === "block") setBlocked(true);
          if (action === "unblock") setBlocked(false);
        } else {
          setNotify({ title, subtitle: data.message, kind: "error" });
        }
        resetNotification();
      })
      .catch((e) => {
        setNotify({
          title,
          subtitle: e.type,
          caption: e.message,
          kind: "error",
        });
        resetNotification();
      });
  };

  React.useEffect(() => {
    const data = { token, url: user.photoUrl };
    const getImage = async () => {
      adminService.fetchFile(data).then((file) => {
        if (file) setUserImage(file);
      });
    };
    if (user.photoUrl && user.photoUrl.includes("digitalocean")) {
      getImage();
    } else {
      if (user.photoUrl && user.photoUrl !== "") {
        setUserImage(user.photoUrl);
      }
    }
    // console.log('photo', user.photoUrl);
  }, [token, user]);

  return (
    <ContentContainer>
      <ContentGrid>
        <Breadcrumbs links={pageLinks} />
        <Row className="user-detail__r1">
          <Column lg={10}>
            <Row>
              <Column lg={2}>
                <img
                  className="user__profile_photo"
                  src={userImage}
                  alt="Avatar"
                ></img>
              </Column>
              <Column lg={{ span: 14 }}>
                <h1>
                  {user.lastName}, {user.firstName}
                </h1>
              </Column>
            </Row>
            <Row className="user-detail__status_r1">
              <Column lg={2}>
                <div className="user-detail__status">
                  <CheckStatus value={verified} />
                  <FormLabel className="user-detail__status_text user-detail__label-header">
                    Verified
                  </FormLabel>
                </div>
              </Column>
              <Column lg={2}>
                <div className="user-detail__status">
                  <CheckStatus value={blocked} />
                  <FormLabel className="user-detail__label-header user-detail__status_text">
                    Blocked
                  </FormLabel>
                </div>
              </Column>
              <Column lg={12}>
                <ActionsButtons
                  verifyUser={() => userActions("verify")}
                  blockUser={() => userActions("block")}
                  unblockUser={() => userActions("unblock")}
                />
              </Column>
            </Row>
          </Column>

          {/* </Column> */}
          {/* <Column lg={{ span: 8 }} className="user-detail__c3">
						<ActionsButtons
							verifyUser={() => userActions('verify')}
							blockUser={() => userActions('block')}
							unblockUser={() => userActions('unblock')}
						/>
					</Column> */}
        </Row>
        {notify.title !== "" && <ActionNotifications {...notify} />}

        <Row className="user-detail__r3">
          <Column lg={16}>
            <Tabs light={true}>
              <Tab id="user-profile" label="Personal">
                <Row>
                  <Column lg={8} className="user-detail__c3">
                    <UserProfileTile
                      user={user}
                      onSave={(data) => userActions("editProfile", data)}
                    />
                    <UserPhoneNumberTile user={user} />
                    <UserEmailTile
                      user={user}
                      onSave={(data) => userActions("addEmail", data)}
                    />
                  </Column>
                  <Column lg={8} className="user-detail__c3">
                    <UserEmploymentTile
                      user={user}
                      onSave={(data) => userActions("addEmployment", data)}
                    />
                    <UserAddressTile
                      user={user}
                      onSave={(data) => userActions("addAddress", data)}
                    />
                  </Column>
                </Row>
              </Tab>
              <Tab id="user-documents" label="Documents">
                <Row>
                  <Column lg={16} className="user-detail__c3">
                    <UserDocumentsTile
                      user={user}
                      onSave={(data) => userActions("addDocument", data)}
                    />
                  </Column>
                </Row>
              </Tab>
              <Tab id="user-pools" label="Pools">
                <Row>
                  <Column lg={16} className="user-detail__c3">
                    <UserPoolsTile userId={user.userId} />
                  </Column>
                </Row>
              </Tab>

              <Tab id="user-payments" label="Payments">
                <Row>
                  <Column lg={16} className="user-detail__c3">
                    <UserPaymentsTile userId={user.userId} />
                  </Column>
                </Row>
              </Tab>
              <Tab id="user-mandates" label="Mandates">
                <Row>
                  <Column lg={16} className="user-detail__c3">
                    <UserMandatesTile userId={user.userId} />
                  </Column>
                </Row>
              </Tab>
              <Tab id="user-notes" label="Notes">
                <Row>
                  <Column lg={16} className="user-detail__c3">
                    <UserNotesTile
                      user={user}
                      onSave={(data) => userActions("addNotes", data)}
                    />
                  </Column>
                </Row>
              </Tab>
            </Tabs>
          </Column>
        </Row>
      </ContentGrid>
    </ContentContainer>
  );
};

export default UserDetailsPage;
