import React from 'react';
import { Row, Column, Tile, Button, TextInput } from 'carbon-components-react';

import { Edit20 as Edit, Save20 as Save, Close20 as Close } from '@carbon/icons-react';

import LabeledData from '../../components/LabeledData';

const PhoneNumberView = (props) => (
	<>
		<Button size="sm" className="user-detail__button_icon" kind="ghost" role="button" onClick={props.onEdit}>
			<Edit className="user-detail__icon-actions" description="Edit phone number" />
		</Button>
		<Row className="user-detail__row_data">
			<Column lg={10}>
				<LabeledData labelText="Phone Number" value={props.phoneNumber} />
			</Column>
		</Row>
	</>
);

const PhoneNumberEdit = (props) => (
	<>
		<div className="user-detail__button_icon">
			<Button size="sm" onClick={props.onSubmit} role="button" kind="ghost" className="user-detail__save-button">
				<Save className="user-detail__icon-actions" description="Save changes" />
			</Button>
			<Button size="sm" onClick={props.onCancel} role="button" kind="ghost" className="user-detail__save-button">
				<Close className="user-detail__icon-actions" description="Cancel changes" />
			</Button>
		</div>
		<Row className="user-detail__row_data">
			<Column lg={10}>
				<TextInput
					light
					labelText="Phone Number"
					value={props.phoneNumber}
					onChange={(e) => props.setPhoneNumber(e.target.value)}
				/>
			</Column>
		</Row>
	</>
);

const UserPhoneNumberTile = ({ user, onSave }) => {
	const [mode, setMode] = React.useState('view');
	const [phoneNumber, setPhoneNumber] = React.useState(user.phoneNumbers[0].phoneNumber);

	const onCancel = () => {
		setPhoneNumber(user.phoneNumbers[0].phoneNumber);
		setMode('view');
	};

	const onEdit = () => {
		setMode('edit');
	};

	const onSubmit = () => {
		onSave({ phoneNumber });
		setMode('view');
	};

	return (
		<Tile className="user-detail__tile_phone_email">
			<h3 className="user-detail__tile_header">Phone Number</h3>
			{mode === 'view' && <PhoneNumberView phoneNumber={phoneNumber} onEdit={onEdit} />}
			{mode === 'edit' && (
				<PhoneNumberEdit
					setPhoneNumber={setPhoneNumber}
					phoneNumber={phoneNumber}
					onCancel={onCancel}
					onSubmit={onSubmit}
				/>
			)}
		</Tile>
	);
};

export default UserPhoneNumberTile;
