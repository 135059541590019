import React from 'react';
import { Link } from 'react-router-dom';

import { Breadcrumb, BreadcrumbItem } from 'carbon-components-react';

const Breadcrumbs = ({ links }) => {
	return (
		<Breadcrumb className="content-container__breadcrumbs">
			{links.map((item, i) => (
				<BreadcrumbItem key={i} isCurrentPage={item.isCurrent}>
					<Link to={item.path}>{item.name}</Link>
				</BreadcrumbItem>
			))}
		</Breadcrumb>
	);
};

export default Breadcrumbs;
