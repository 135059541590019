import React from 'react';

import { LineChart } from '@carbon/charts-react';

const options = {
	title: 'Resource Utilization',
	legend: {
		alignment: 'center',
	},
	axes: {
		bottom: {
			title: 'Timestamp',
			mapsTo: 'date',
			scaleType: 'time',
		},
		left: {
			title: 'Percentage (%)',
			mapsTo: 'value',
			scaleType: 'linear',
			// percentage: true,
		},
	},
	timeScale: {
		addSpaceOnEdges: 0,
	},
	curve: 'curveNatural',
	height: '350px',
	width: '95%',
	animations: true,
};

const UsageChart = ({ data }) => {
	return <LineChart data={data} options={options}></LineChart>;
};
export default UsageChart;
