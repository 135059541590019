import React from 'react';
import { Tile, Pagination } from 'carbon-components-react';

import { AuthContext } from '../../store';
import { adminService } from '../../services';

import GenericTable from '../../components/GenericTable';

const headers = [
	{ key: 'poolId', header: 'Pool ID' },
	{ key: 'type', header: 'Type' },
	{ key: 'status', header: 'Status' },
	{ key: 'creator', header: 'Creator' },
	{ key: 'balance', header: 'Balance' },
	{ key: 'depositAmount', header: 'Deposit Amount' },
	{ key: 'depositRate', header: 'Deposit Rate' },
	{ key: 'payoutRate', header: 'Payout Rate' },
	{ key: 'startDate', header: 'Start Date' },
	{ key: 'createdAt', header: 'Date Created' },
];

const UserPoolsTile = ({ userId }) => {
	const [{ token }] = React.useContext(AuthContext);
	const [data, setData] = React.useState([]);
	const [totalItems, setTotalItems] = React.useState(0);

	const paginate = ({ page, pageSize }) => {
		adminService
			.post('/fetchUserPools', { token, userId, offset: (page - 1) * pageSize, limit: pageSize })
			.then((data) => {
				if (data.ok) {
					setTotalItems(data.rowLength);
					setData(data.rows.map((row) => row.pool));
				}
			});
	};

	React.useEffect(() => {
		const fetchPayments = () => {
			adminService.post('/fetchUserPools', { token, userId }).then((data) => {
				if (data.ok) {
					setTotalItems(data.rowLength);
					setData(data.rows.map((row) => row.pool));
				}
			});
		};
		fetchPayments();
	}, [token, userId]);

	return (
		<Tile className="user-detail__tile_payments">
			<h3 className="user-detail__tile_header">Pools</h3>
			<div className="user-payment__table">
				{totalItems > 0 && (
					<>
						<GenericTable key="users" size="normal" headers={headers} data={data} />
						<Pagination
							onChange={paginate}
							pageSize={10}
							totalItems={totalItems}
							page={1}
							pageSizes={[10, 20, 30, 40, 50]}
						/>
					</>
				)}
				{totalItems === 0 && <p className="user-detail__no_items">There are no pools for this user yet!</p>}
			</div>
		</Tile>
	);
};

export default UserPoolsTile;
