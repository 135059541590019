import React from 'react';
// import ReactDOM from "react-dom";
import { SimpleBarChart } from '@carbon/charts-react';
// import "@carbon/charts/styles.css";
// Or
// import "@carbon/charts/styles/styles.scss";

// IBM Plex should either be imported in your project by using Carbon
// or consumed manually through an import
import './ibm-plex-font.scss';

const data = [
	{
		group: 'January',
		value: 1.2,
	},
	{
		group: 'February',
		value: 2.2,
	},
	{
		group: 'March',
		value: 3.5,
	},
	{
		group: 'April',
		value: 5.5,
	},
	{
		group: 'May',
		value: 7.6,
	},
];
const options = {
	title: 'Monthly Payments Processed (million GHC)',
	axes: {
		left: {
			mapsTo: 'value',
		},
		bottom: {
			mapsTo: 'group',
			scaleType: 'labels',
		},
	},
	height: '300px',
};

const BarGraph = () => {
	return <SimpleBarChart data={data} options={options}></SimpleBarChart>;
};

export default BarGraph;
