import React from 'react';

import { Tile, Form, TextInput, Button, InlineNotification } from 'carbon-components-react';
import { ArrowRight20 as Arrow } from '@carbon/icons-react';

import { AuthContext, login } from '../../store';

const props = {
	tile: {
		light: false,
	},
	email: {
		className: 'login-email',
		id: 'email',
		helperText: 'Enter your @susupond.com email address',
		invalidText: 'Email must contain @susupond.com',
		labelText: 'SUSUid',
		placeholder: 'ama.mensah@susupond.com',
	},
	password: {
		id: 'password',
		helperText: 'Enter your SUSUid password',
		invalidText: 'Password cannot be empty.',
		labelText: 'Password',
		placeholder: '',
		hidePasswordLabel: 'Hide Password',
		showPasswordLabel: 'Show password',
	},
	button: {
		kind: 'primary',
		type: 'submit',
		size: 'default',
		tabIndex: 0,
	},
	notification: {
		kind: 'error',
		title: 'Login Failed',
		iconDescription: 'close',
	},
};

const LoginPage = () => {
	const [disableButton, setDisableButton] = React.useState(true);
	const [validEmail, setValidEmail] = React.useState(true);
	const [validPassword, setValidPassword] = React.useState(true);
	const [email, setEmail] = React.useState('');
	const [password, setPassword] = React.useState('');
	const [{ error }, dispatch] = React.useContext(AuthContext);
	// const { error } = state;

	const validateEmail = (value) => {
		if (/@susupond.com\s*$/.test(value)) {
			setValidEmail(true);
			if (password !== '' && validPassword) {
				setDisableButton(false);
			}
		} else {
			setValidEmail(false);
			setDisableButton(true);
		}
	};

	const validatePassword = (value) => {
		if (value === '') {
			setValidPassword(false);
			setDisableButton(true);
		} else {
			setValidPassword(true);
			if (email !== '' && validEmail) {
				setDisableButton(false);
			}
		}
	};

	const resetFields = () => {
		setEmail('');
		setPassword('');
		setValidEmail(true);
		setValidPassword(true);
		setDisableButton(true);
	};

	const onClick = (e) => {
		e.preventDefault();
		resetFields();
		login({ dispatch }, { email, password });
	};

	return (
		<div className="login-page">
			<div className="login-page__content">
				{error && (
					<InlineNotification
						className="login-page__notification"
						{...props.notification}
						subtitle={error.message}
					/>
				)}
				<Tile {...props.tile} className="login-page__form-tile">
					<h3 className="login-page__form-header">Log in</h3>
					<Form>
						<div className="login-page__form-field">
							<TextInput
								{...props.email}
								light
								value={email}
								invalid={!validEmail}
								onChange={({ target }) => {
									const { value } = target;
									validateEmail(value);
									setEmail(value);
								}}
							/>
						</div>
						<div className="login-page__form-field">
							<TextInput.PasswordInput
								{...props.password}
								light
								value={password}
								invalid={!validPassword}
								onChange={({ target }) => {
									const { value } = target;
									validatePassword(value);
									setPassword(value);
								}}
							/>
						</div>
						{/* <Button
							{...props.button}
							disabled={disableButton}
							className="login-page__button"
							onClick={(e) => onClick(e)}
							renderIcon={Arrow}
							kind="secondary"
							size="lg"
						>
							Cancel
						</Button> */}

						<Button
							{...props.button}
							disabled={disableButton}
							className="login-page__button"
							onClick={(e) => onClick(e)}
							renderIcon={Arrow}
							size="lg"
						>
							Log in
						</Button>
					</Form>
				</Tile>
			</div>
		</div>
	);
};

export default LoginPage;
