import React from "react";

import {
  DataTable,
  TableContainer,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
  // Button,
  // Search,
} from "carbon-components-react";

import { CellValue, CellLink } from "../../components/CellValue";

const UsersTable = ({ data, headers }) => {
  const [rows, setRows] = React.useState(data);
  // const [string, setString] = React.useState("");

  React.useEffect(() => {
    setRows(data);
  }, [data]);

  return (
    <DataTable rows={rows} headers={headers} id="users-table">
      {({
        rows,
        headers,
        getHeaderProps,
        getRowProps,
        getToolbarProps,
        getBatchActionProps,
        onInputChange,
        getTableProps,
        getTableContainerProps,
      }) => (
        <TableContainer
          className="users-page__table"
          title="Users List"
          description=""
          {...getTableContainerProps()}
        >
          <TableToolbar {...getToolbarProps()}>
            <TableToolbarContent>
              <TableToolbarSearch
                defaultExpanded
                tabIndex={getBatchActionProps().shouldShowBatchActions ? -1 : 0}
                onChange={onInputChange}
              />
            </TableToolbarContent>
          </TableToolbar>
          <Table {...getTableProps()}>
            <TableHead>
              <TableRow>
                {headers.map((header, i) => (
                  <TableHeader key={i} {...getHeaderProps({ header })}>
                    {header.header}
                  </TableHeader>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, i) => (
                <React.Fragment key={row.id}>
                  <TableRow {...getRowProps({ row })}>
                    {row.cells.map((cell) => (
                      <TableCell
                        key={cell.id}
                        className={
                          cell.id.includes("verified") ||
                          cell.id.includes("blocked")
                            ? "user-table__icon-center"
                            : ""
                        }
                      >
                        {cell.id.includes("userId") ? (
                          <CellLink key={cell.id} {...cell} data={data[i]} />
                        ) : (
                          <CellValue key={cell.id} {...cell} />
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </DataTable>
  );
};

export default UsersTable;
