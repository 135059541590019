import React from "react";
import "./app.scss";

import { BrowserRouter as Router, useLocation } from "react-router-dom";
import { LoadingRoute, ContentRoute } from "./router";
import { AuthProvider, subscribe, AuthContext } from "./store";

const BaseApp = () => {
  const [{ isAuthenticated, isLoading }, dispatch] = React.useContext(
    AuthContext
  );
  const { pathname } = useLocation();

  React.useEffect(() => {
    subscribe({ dispatch });
    return () => [];
  }, [dispatch]);

  if (isAuthenticated === false && isLoading === true) {
    return <LoadingRoute />;
  }

  return <ContentRoute path={pathname} />;
};

const App = () => (
  <Router>
    <AuthProvider>
      <BaseApp />
    </AuthProvider>
  </Router>
);

export default App;
