import React from "react";

import { Row, Column } from "carbon-components-react";
import {
  ContentContainer,
  ContentGrid,
} from "../../components/ContentContainer";
import { AuthContext, DataContext, fetchAll } from "../../store";

import {
  DashboardTile,
  ServicesTile,
  // PaymentsTile,
  PoolsTile,
  UsersTile,
} from "./TaskTiles";
import TutorialsTile from "./TutorialsTile";
import AnnouncementsTile from "./AnnouncementsTile";
import { urlPath } from "../../utils";
import data from "./data";

const HomePage = ({ history }) => {
  const [{ user, token }] = React.useContext(AuthContext);
  const [, dispatch] = React.useContext(DataContext);

  React.useEffect(() => {
    fetchAll({ dispatch, token });
  }, [dispatch, token]);

  return (
    <ContentContainer>
      <ContentGrid>
        <h1 className="home-page__header">
          Welcome, {user ? user.displayName : ""}
        </h1>
        <p className="home-page__text">Get Started with a few key tasks:</p>
        <Row className="home-page__r1">
          <Column lg={4}>
            <DashboardTile
              onClick={() => history.push(urlPath("/dashboard"))}
            />
          </Column>
          <Column lg={4}>
            <UsersTile onClick={() => history.push(urlPath("/users"))} />
          </Column>
          <Column lg={4}>
            <PoolsTile onClick={() => history.push(urlPath("/pools"))} />
          </Column>
          <Column lg={4}>
            <ServicesTile onClick={() => history.push(urlPath("/services"))} />
          </Column>
        </Row>
        <Row className="home-page__r2">
          <Column lg={8}>
            <TutorialsTile items={data.tutorials} />
          </Column>
          <Column lg={8}>
            <AnnouncementsTile items={data.announcements} />
          </Column>
        </Row>
      </ContentGrid>
    </ContentContainer>
  );
};
export default HomePage;
