import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/database';
import { v4 as uuid } from 'uuid';

const firebaseConfig = {
	apiKey: 'AIzaSyC-Qdtjm7J9o7sQGBDrEHbpIiMHmG6lmio',
	authDomain: 'susupond-8c230.firebaseapp.com',
	databaseURL: 'https://susupond-8c230.firebaseio.com',
	projectId: 'susupond-8c230',
	storageBucket: 'susupond-8c230.appspot.com',
	messagingSenderId: '10464791144',
	appId: '1:10464791144:web:3ee6702c7588908c8e49dd',
	measurementId: 'G-XZS9RQF2CL',
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
const auth = firebase.auth();
const firestore = firebase.firestore();
const db = firebase.database();
const storage = firebase.storage();
const authPersistence = firebase.auth.Auth.Persistence;

const uploadFile = async ({ bucket, documentFile }) => {
	try {
		const metadata = {
			contentType: documentFile.type,
			cacheControl: 'public, max-age=86400',
			metadata: {
				firebaseStorageDownloadTokens: uuid(),
			},
		};
		const storageRef = storage.ref(`${bucket}/` + documentFile.name);
		await storageRef.put(documentFile, metadata);
		const documentUrl = await storageRef.getDownloadURL();
		return documentUrl;
	} catch (err) {
		console.log(err);
	}
};

export { auth, db, storage, firestore, authPersistence, uploadFile };
