import React from 'react';

import { Tag } from 'carbon-components-react';

const Status = {
	0: (
		<Tag type="gray" className="payment__status_tag">
			unknown
		</Tag>
	),
	1: (
		<Tag type="purple" className="payment__status_tag">
			initialized
		</Tag>
	),
	2: (
		<Tag type="green" className="payment__status_tag">
			completed
		</Tag>
	),
	3: (
		<Tag type="red" className="payment__status_tag">
			failed
		</Tag>
	),
};

const PaymentStatus = ({ value }) => {
	return Status[value];
};

export default PaymentStatus;
