import React from "react";

import { InlineLoading, Pagination } from "carbon-components-react";

import { AuthContext, DataContext, fetchData } from "../../store";

import {
  ContentGrid,
  ContentContainer,
} from "../../components/ContentContainer";
import Breadcrumbs from "../../components/Breadcrumbs";
import { ServicesTable } from "../../components/ServicesTable";
// import TablePagination from "../../components/TablePagination";

const pageLinks = [{ name: "Services", path: "/services", isCurrent: false }];
const headers = [
  { key: "shortId", header: "Container Id" },
  { key: "name", header: "Container Name" },
  { key: "group", header: "Service Group" },
  { key: "image", header: "Image Id" },
  { key: "tags", header: "Image Name" },
  { key: "state", header: "Status" },
  // { key: "pid", header: "PID" },
  // { key: "description", header: "Uptime" },
  // { key: "now", header: "Last Seen" },
  { key: "startedAgo", header: "Started" },
  { key: "createdAgo", header: "Created" },
];

const ServicesPage = () => {
  const [{ token }] = React.useContext(AuthContext);
  const [{ services }, dispatch] = React.useContext(DataContext);
  const [data, setData] = React.useState(services.rows.slice(0, 10));

  React.useEffect(() => {
    const action = "FETCH_SERVICES";
    const timer = setInterval(
      () => fetchData({ dispatch, token, action }),
      30000
    );
    return () => clearInterval(timer);
  }, [dispatch, token]);

  React.useEffect(() => {
    setData(services.rows.slice(0, 10));
  }, [services]);

  const paginate = ({ page, pageSize }) => {
    const beg = (page - 1) * pageSize;
    setData(services.rows.slice(beg, beg + pageSize));
  };

  return (
    <ContentContainer>
      <ContentGrid>
        <Breadcrumbs links={pageLinks} />
        <h1>Monitor Services</h1>
        {services.failed && (
          <InlineLoading
            status="error"
            description="Error loading pools list"
          ></InlineLoading>
        )}
        {!services.failed && (
          <>
            <ServicesTable headers={headers} data={data} />
            <Pagination
              onChange={paginate}
              pageSize={10}
              totalItems={services.rowLength}
              page={1}
              pageSizes={[10, 20, 30, 40, 50]}
            />
            {/* <TablePagination
              data={services.rows}
              totalItems={services.rowLength}
              paginate={paginate}
            /> */}
          </>
        )}
      </ContentGrid>
    </ContentContainer>
  );
};

export default ServicesPage;
