import React from "react";

import {
  TableContainer,
  TableToolbar,
  Table,
  DataTable,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
} from "carbon-components-react";

import { CellValue } from "../../components/CellValue";

const ServicesTableMinimal = ({ data, headers }) => {
  const [rows, setRows] = React.useState(data);

  React.useEffect(() => {
    setRows(data);
  }, [data]);

  return (
    <DataTable rows={rows} headers={headers}>
      {({
        rows,
        headers,
        getHeaderProps,
        getRowProps,
        getToolbarProps,
        getTableProps,
        getTableContainerProps,
      }) => (
        <TableContainer
          className="services__table_min"
          title=""
          description=""
          {...getTableContainerProps()}
        >
          <TableToolbar {...getToolbarProps()}></TableToolbar>
          <Table {...getTableProps()}>
            <TableHead>
              <TableRow>
                {headers.map((header, i) => (
                  <TableHeader key={i} {...getHeaderProps({ header })}>
                    {header.header}
                  </TableHeader>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, i) => (
                <React.Fragment key={i}>
                  <TableRow {...getRowProps({ row })}>
                    {row.cells.map((cell) => (
                      <TableCell key={cell.id}>
                        <CellValue key={cell.id} {...cell} />
                      </TableCell>
                    ))}
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </DataTable>
  );
};

export default ServicesTableMinimal;
