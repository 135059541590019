import React from 'react';
import { Row, Column, Tile, Button, TextInput, InlineLoading } from 'carbon-components-react';

import { AddAlt20 as Add, Save20 as Save, Close20 as Close } from '@carbon/icons-react';

import { AuthContext } from '../../store';
import { adminService } from '../../services';

import LabeledData from '../../components/LabeledData';

const AddressView = (props) => (
	<>
		<Button size="sm" className="user-detail__button_icon" kind="ghost" role="button" onClick={props.onEdit}>
			<Add className="user-detail__icon-actions" description="Add address" />
		</Button>
		{props.loading && <InlineLoading description="Loading..." />}
		{!props.loading && (
			<>
				{props.failed && <InlineLoading description="Loading error" status="error" />}
				{!props.failed && (
					<>
						{props.rowLength > 0 && (
							<>
								<Row className="user-detail__row_data">
									<Column lg={8}>
										<LabeledData labelText="Street Address" value={props.street} />
									</Column>
									<Column lg={8}>
										<LabeledData labelText="City" value={props.city} />
									</Column>
								</Row>
								<Row className="user-detail__row_data">
									<Column lg={8}>
										<LabeledData labelText="Region" value={props.state} />
									</Column>
									<Column lg={8}>
										<LabeledData labelText="Postcode" value={props.postcode} />
									</Column>
								</Row>
								<Row className="user-detail__row_data">
									<Column lg={8}>
										<LabeledData labelText="Country" value={props.country} />
									</Column>
								</Row>
							</>
						)}
						{props.rowLength === 0 && (
							<p className="user-detail__no_items">User has not added an address yet!</p>
						)}
					</>
				)}
			</>
		)}
	</>
);

const AddressEdit = (props) => (
	<>
		<div className="user-detail__button_icon">
			<Button size="sm" onClick={props.onSubmit} role="button" kind="ghost" className="user-detail__save-button">
				<Save className="user-detail__icon-actions" description="Save changes" />
			</Button>
			<Button size="sm" onClick={props.onCancel} role="button" kind="ghost" className="user-detail__save-button">
				<Close className="user-detail__icon-actions" description="Cancel changes" />
			</Button>
		</div>
		<Row className="user-detail__row_data">
			<Column lg={8}>
				<TextInput light labelText="Street" name="street" value={props.street} onChange={props.handleInput} />
			</Column>
			<Column lg={8}>
				<TextInput light labelText="City" name="city" value={props.city} onChange={props.handleInput} />
			</Column>
		</Row>
		<Row className="user-detail__row_data">
			<Column lg={8}>
				<TextInput light name="state" labelText="Region" value={props.state} onChange={props.handleInput} />
			</Column>
			<Column lg={8}>
				<TextInput
					light
					name="postcode"
					labelText="Post Code"
					value={props.postcode}
					onChange={props.handleInput}
				/>
			</Column>
		</Row>
		<Row className="user-detail__row_data">
			<Column lg={8}>
				<TextInput
					light
					name="country"
					labelText="Country"
					value={props.country}
					onChange={props.handleInput}
				/>
			</Column>
		</Row>
	</>
);

const UserAddressTile = ({ user, onSave }) => {
	const [{ token }] = React.useContext(AuthContext);
	const [userId] = React.useState(user.userId);

	const [addresses, setAddresses] = React.useState({ rowLength: 0 });
	const [status, setStatus] = React.useState({ failed: false, loading: false });
	const [address, setAddress] = React.useState({});
	const [mode, setMode] = React.useState('view');

	React.useEffect(() => {
		const loadAddresses = async () => {
			adminService
				.post('/fetchUserAddresses', { token, userId })
				.then((data) => {
					if (data.ok) {
						if (data.rowLength > 0) {
							setAddress(data.rows[0]);
						}
						setAddresses(data);
						setStatus({ failed: false, loading: false });
					} else {
						setStatus({ failed: true, loading: false });
					}
				})
				.catch((e) => {
					setStatus({ failed: true, loading: false });
				});
		};
		loadAddresses();
	}, [token, userId]);

	const onCancel = () => {
		if (addresses.rowLength > 0) {
			setAddress(addresses.rows[0]);
		} else {
			setAddress({});
		}
		setMode('view');
	};

	const onEdit = () => {
		setMode('edit');
	};

	const handleInput = (e) => {
		const { target } = e;
		const { value, name } = target;
		setAddress({ ...address, [name]: value });
	};

	const onSubmit = () => {
		address.addressId = null;
		const { street, city, state, postcode, country } = address;
		const data = {
			rowLength: addresses.rowLength + 1,
			rows: [address],
		};
		onSave({ street, city, state, postcode, country });

		// update address array
		data.rows.push(...addresses.rows);
		setAddresses(data);
		setMode('view');
	};

	return (
		<Tile className="user-detail__tile_address">
			<h3 className="user-detail__tile_header">Address</h3>
			{mode === 'view' && (
				<AddressView {...status} {...address} onEdit={onEdit} rowLength={addresses.rowLength} />
			)}
			{mode === 'edit' && (
				<AddressEdit {...address} onCancel={onCancel} onSubmit={onSubmit} handleInput={handleInput} />
			)}
		</Tile>
	);
};

export default UserAddressTile;
