import React from 'react';
import { Row, Column, Tile, Button, TextInput, DatePicker, DatePickerInput } from 'carbon-components-react';

import { Edit20 as Edit, Save20 as Save, Close20 as Close } from '@carbon/icons-react';

import { formatDate, formatISODate } from '../../utils';

import LabeledData from '../../components/LabeledData';

const ProfileView = (props) => (
	<>
		<Button size="sm" className="user-detail__button_icon" kind="ghost" role="button" onClick={props.onEdit}>
			<Edit className="user-detail__icon-actions" description="Edit profile" />
		</Button>
		<Row className="user-detail__row_data">
			<Column lg={8}>
				<LabeledData labelText="First Name" value={props.firstName} />
			</Column>
			<Column lg={8}>
				<LabeledData labelText="Last Name" value={props.lastName} />
			</Column>
		</Row>
		<Row className="user-detail__row_data">
			<Column lg={8}>
				<LabeledData labelText="Date of Birth" value={formatDate(props.dateOfBirth)} />
			</Column>
			<Column lg={8}>
				<LabeledData labelText="Nationality" value={props.nationality} />
			</Column>
		</Row>
	</>
);

const ProfileEdit = (props) => (
	<>
		<div className="user-detail__button_icon">
			<Button size="sm" onClick={props.onSubmit} role="button" kind="ghost" className="user-detail__save-button">
				<Save className="user-detail__icon-actions" description="Save changes" />
			</Button>
			<Button size="sm" onClick={props.onCancel} role="button" kind="ghost" className="user-detail__save-button">
				<Close className="user-detail__icon-actions" description="Cancel changes" />
			</Button>
		</div>
		<Row className="user-detail__row_data">
			<Column lg={8}>
				<TextInput
					light
					name="firstName"
					labelText="First Name"
					value={props.firstName}
					onChange={props.handleInput}
				/>
			</Column>
			<Column lg={8}>
				<TextInput
					light
					name="lastName"
					labelText="Last Name"
					value={props.lastName}
					onChange={props.handleInput}
				/>
			</Column>
		</Row>
		<Row className="user-detail__row_data">
			<Column lg={8}>
				<DatePicker
					dateFormat="Y-m-d"
					datePickerType="single"
					light
					name="dateOfBirth"
					className="user-detail__date_picker"
					value={props.dateOfBirth}
					onChange={(e) =>
						props.handleInput({
							target: {
								value: formatISODate(e[0]),
								name: 'dateOfBirth',
							},
						})
					}
				>
					<DatePickerInput className="user-detail__date_picker" labelText="Date of Birth" type="text" />
				</DatePicker>
			</Column>
			<Column lg={8}>
				<TextInput
					name="nationality"
					light
					labelText="Nationality"
					value={props.nationality}
					onChange={props.handleInput}
				/>
			</Column>
		</Row>
	</>
);

const UserProfileTile = ({ user, onSave }) => {
	const [mode, setMode] = React.useState('view');
	const [state, setState] = React.useState({ ...user });

	const onCancel = () => {
		setState({ ...user });
		setMode('view');
	};

	const onEdit = () => {
		setMode('edit');
	};

	const onSubmit = () => {
		onSave(state);
		setMode('view');
	};

	const handleInput = (e) => {
		const { target } = e;
		const { value, name } = target;
		setState({ ...state, [name]: value });
	};

	return (
		<Tile className="user-detail__tile_profile">
			<h3 className="user-detail__tile_header">Profile</h3>
			{mode === 'view' && <ProfileView {...state} onEdit={onEdit} />}
			{mode === 'edit' && (
				<ProfileEdit {...state} onCancel={onCancel} onSubmit={onSubmit} handleInput={handleInput} />
			)}
		</Tile>
	);
};

export default UserProfileTile;
