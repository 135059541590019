import React from "react";
import { DonutChart } from "@carbon/charts-react";

const options = {
  title: "Pool Types",
  resizable: true,
  legend: {
    alignment: "center",
  },
  donut: {
    center: {
      label: "Pools Created",
    },
    alignment: "center",
  },
  height: "300px",
};

const DonutGraph = ({ data }) => {
  return <DonutChart data={data} options={options}></DonutChart>;
};

export default DonutGraph;
