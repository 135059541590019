import React from 'react';
import { FormLabel } from 'carbon-components-react';

const LabeledData = ({ labelText, value }) => {
	return (
		<>
			<FormLabel className="labeled__data__text">{labelText}</FormLabel>
			<p className="labeled__data__value"> {value} </p>
		</>
	);
};

export default LabeledData;
