import React from "react";
import {
  Header,
  HeaderContainer,
  HeaderName,
  HeaderMenuButton,
  HeaderGlobalBar,
  HeaderGlobalAction,
  SkipToContent,
  SideNav,
  SideNavItems,
  SideNavLink,
  SwitcherDivider,
} from "carbon-components-react";

import {
  AppSwitcher20 as AppSwitcher,
  Notification20 as Notification,
  UserAvatar20 as UserAvatar,
  Logout20 as Logout,
  // Wallet20 as Wallet,
  Dashboard20 as Dashboard,
  AppConnectivity20 as AppConnectivity,
  Home20 as Home,
  Partnership20 as Partnership,
  Group20 as Group,
  Settings20 as Settings,
} from "@carbon/icons-react";
import { Link, useHistory } from "react-router-dom";

import { AuthContext, logout } from "../../store";
import { urlPath } from "../../utils";

const MainHeader = ({ path }) => {
  const [{ user }, dispatch] = React.useContext(AuthContext);
  const history = useHistory();
  const onLogout = () => {
    logout({ dispatch });
    history.push(urlPath("/login"));
  };

  return (
    <HeaderContainer
      render={({ onClickSideNavExpand }) => (
        <Header aria-label="SUSUPOND">
          <SkipToContent />
          <HeaderMenuButton
            aria-label="Open menu"
            isCollapsible
            onClick={onClickSideNavExpand}
          />
          <HeaderName element={Link} to={urlPath("/")} prefix="SUSU">
            SUSUPOND
          </HeaderName>
          <SideNav
            // isRail
            expanded={false}
            isChildOfHeader={true}
            aria-label="Side navigation"
            className="main-header__side-nav"
          >
            <SideNavItems>
              <SideNavLink
                renderIcon={Home}
                element={Link}
                to={urlPath("/")}
                isActive={path === urlPath("/")}
              >
                Home
              </SideNavLink>
              <SideNavLink
                renderIcon={Dashboard}
                element={Link}
                to={urlPath("/dashboard")}
                isActive={path === urlPath("/dashboard")}
              >
                Dashboard
              </SideNavLink>
              <SideNavLink
                renderIcon={Group}
                element={Link}
                to={urlPath("/users")}
                isActive={path === urlPath("/users")}
              >
                Users
              </SideNavLink>
              <SideNavLink
                renderIcon={Partnership}
                element={Link}
                to={urlPath("/pools")}
                isActive={path === urlPath("/pools")}
              >
                Pools
              </SideNavLink>
              {/* <SideNavLink
								renderIcon={Wallet}
								element={Link}
								to={urlPath("/payments")}
								isActive={path === urlPath("/payments")}
							>
								Payments
							</SideNavLink> */}

              <SideNavLink
                renderIcon={AppConnectivity}
                element={Link}
                to={urlPath("/services")}
                isActive={path === urlPath("/services")}
              >
                Services
              </SideNavLink>

              <SwitcherDivider></SwitcherDivider>
              <SideNavLink
                renderIcon={Settings}
                element={Link}
                to={urlPath("/settings")}
                isActive={path === urlPath("/settings")}
              >
                Settings
              </SideNavLink>
            </SideNavItems>
          </SideNav>

          <HeaderGlobalBar>
            <HeaderGlobalAction aria-label="Log out" onClick={onLogout}>
              <Logout />
            </HeaderGlobalAction>
            <HeaderGlobalAction aria-label="Notifications">
              <Notification />
            </HeaderGlobalAction>
            <div className="main-header__user">
              <p className="main-header__email">
                {user ? user.displayName : ""}
              </p>
              <p className="main-header__uid">{user ? user.email : ""}</p>
            </div>
            <HeaderGlobalAction aria-label="User Profile">
              <UserAvatar />
            </HeaderGlobalAction>
            <HeaderGlobalAction aria-label="App Switcher">
              <AppSwitcher />
            </HeaderGlobalAction>
          </HeaderGlobalBar>
        </Header>
      )}
    />
  );
};

export default MainHeader;
