import React from 'react';

import { InlineLoading, Pagination } from 'carbon-components-react';
import { useHistory } from 'react-router-dom';

import { ContentGrid, ContentContainer } from '../../components/ContentContainer';
import Breadcrumbs from '../../components/Breadcrumbs';

import { DataContext, AuthContext } from '../../store';
import { adminService } from '../../services';

import UsersTable from './UsersTable';

const pageLinks = [{ name: 'Users', path: '/users', isCurrent: false }];

const headers = [
	{ key: 'userId', header: 'User ID' },
	{ key: 'lastName', header: 'Last Name' },
	{ key: 'firstName', header: 'First Name' },
	{ key: 'emails', header: 'Email' },
	{ key: 'employments', header: 'Occupation' },
	{ key: 'dateOfBirth', header: 'Date of Birth' },
	{ key: 'verified', header: 'Verified' },
	{ key: 'blocked', header: 'Blocked' },
	{ key: 'createdAt', header: 'Date Joined' },
];
const UsersPage = () => {
	const [{ token }] = React.useContext(AuthContext);
	const [{ users }] = React.useContext(DataContext);
	const [data, setData] = React.useState(users.rows.slice(0, 10));
	const [totalItems, setTotalItems] = React.useState(users.rowLength);
	const history = useHistory();

	const paginate = ({ page, pageSize }) => {
		const data = { token, offset: (page - 1) * pageSize, limit: pageSize };
		adminService.post('/fetchUsers', data).then((data) => {
			if (data.ok) {
				setData(data.rows);
				setTotalItems(data.rowLength);
			}
		});
	};

	React.useEffect(() => {
		setData(users.rows.slice(0, 10));
	}, [users]);

	return (
		<ContentContainer>
			<ContentGrid>
				<Breadcrumbs links={pageLinks} />
				<h1>Administer Users</h1>
				{users.failed && <InlineLoading status="error" description="Error loading users list"></InlineLoading>}
				{!users.failed && (
					<>
						<UsersTable data={data} headers={headers} history={history} />
						<Pagination
							onChange={paginate}
							pageSize={10}
							totalItems={totalItems}
							page={1}
							pageSizes={[10, 20, 30, 40, 50]}
						/>
					</>
				)}
			</ContentGrid>
		</ContentContainer>
	);
};

export default UsersPage;
