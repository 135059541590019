import React from 'react';

import {
	TableContainer,
	TableToolbar,
	TableToolbarContent,
	TableToolbarSearch,
	Table,
	DataTable,
	TableHead,
	TableRow,
	TableExpandHeader,
	TableExpandRow,
	TableExpandedRow,
	TableHeader,
	TableBody,
	TableCell,
	CodeSnippet,
} from 'carbon-components-react';

import { CellValue } from '../../components/CellValue';

const vendorText = (data) => {
	return `
	Vendor Conversation ID : ${data.output_ConversationID}
	Vendor Transaction ID : ${data.output_TransactionID}
	Vendor Transaction Reference : ${data.output_TransactionReference}
	Vendor Response Description : ${data.output_ResponseDesc}
	Vendor Response Code : ${data.output_ResponseCode}
	`;
};

const PaymentsTable = ({ title, description, data, headers, size, className }) => {
	const [rows, setRows] = React.useState(data);

	React.useEffect(() => {
		setRows(data);
	}, [data]);

	return (
		<DataTable rows={rows} headers={headers}>
			{({
				rows,
				headers,
				getHeaderProps,
				getRowProps,
				getToolbarProps,
				getBatchActionProps,
				onInputChange,
				getTableProps,
				getTableContainerProps,
			}) => (
				<TableContainer
					className={className ? className : ''}
					title={title ? title : ''}
					description={description ? description : ''}
					{...getTableContainerProps()}
				>
					<TableToolbar {...getToolbarProps()}>
						<TableToolbarContent>
							<TableToolbarSearch
								defaultExpanded
								tabIndex={getBatchActionProps().shouldShowBatchActions ? -1 : 0}
								onChange={onInputChange}
							/>
						</TableToolbarContent>
					</TableToolbar>
					<Table {...getTableProps()} size={size ? size : 'normal'}>
						<TableHead>
							<TableRow>
								<TableExpandHeader />
								{headers.map((header, i) => (
									<TableHeader key={i} {...getHeaderProps({ header })}>
										{header.header}
									</TableHeader>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{rows.map((row, i) => (
								<React.Fragment key={row.id}>
									<TableExpandRow {...getRowProps({ row })}>
										{row.cells.map((cell) => (
											<TableCell>
												<CellValue key={cell.id} {...cell} />
											</TableCell>
										))}
									</TableExpandRow>
									<TableExpandedRow colSpan={headers.length + 1}>
										<div className="payments-table__expanded">
											<CodeSnippet type="multi">
												{data[i] ? vendorText(data[i].vendorResponse) : ''}
											</CodeSnippet>
										</div>
									</TableExpandedRow>
								</React.Fragment>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			)}
		</DataTable>
	);
};

export default PaymentsTable;
