import React from 'react';

import { InlineLoading, Pagination } from 'carbon-components-react';
import { useHistory } from 'react-router-dom';

import { ContentGrid, ContentContainer } from '../../components/ContentContainer';
import Breadcrumbs from '../../components/Breadcrumbs';
// import TablePagination from '../../components/TablePagination';

import { DataContext, AuthContext } from '../../store';
import { adminService } from '../../services';

import PoolsTable from './PoolsTable';

const pageLinks = [{ name: 'Pools', path: '/pools', isCurrent: false }];
const headers = [
	{ key: 'poolId', header: 'Pool ID' },
	{ key: 'type', header: 'Type' },
	{ key: 'status', header: 'Status' },
	{ key: 'creator', header: 'Creator' },
	{ key: 'balance', header: 'Balance' },
	{ key: 'depositAmount', header: 'Deposit Amount' },
	{ key: 'depositRate', header: 'Deposit Rate' },
	{ key: 'payoutRate', header: 'Payout Rate' },
	{ key: 'startDate', header: 'Start Date' },
	{ key: 'createdAt', header: 'Date Created' },
];

const PoolsPage = () => {
	const [{ token }] = React.useContext(AuthContext);
	const [{ pools }] = React.useContext(DataContext);
	const history = useHistory();
	const [data, setData] = React.useState(pools.rows.slice(0, 10));
	const [totalItems, setTotalItems] = React.useState(pools.rowLength);

	const paginate = ({ page, pageSize }) => {
		const data = { token, offset: (page - 1) * pageSize, limit: pageSize };
		adminService.post('/fetchPools', data).then((data) => {
			if (data.ok) {
				setData(data.rows);
				setTotalItems(data.rowLength);
			}
		});
	};

	return (
		<ContentContainer>
			<ContentGrid>
				<Breadcrumbs links={pageLinks} />
				<h1>Manage Pools</h1>
				{pools.failed && <InlineLoading status="error" description="Error loading pools list"></InlineLoading>}
				{!pools.failed && (
					<>
						<PoolsTable data={data} headers={headers} history={history} />
						<Pagination
							onChange={paginate}
							pageSize={10}
							totalItems={totalItems}
							page={1}
							pageSizes={[10, 20, 30, 40, 50]}
						/>
					</>
				)}
			</ContentGrid>
		</ContentContainer>
	);
};

export default PoolsPage;
