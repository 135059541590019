import React from "react";
import { Tile, Pagination } from "carbon-components-react";

import { AuthContext } from "../../store";
import { adminService } from "../../services";

import MandatesTable from "../../components/MandatesTable";

const headers = [
  { key: "mandateId", header: "Mandate ID" },
  { key: "mandateReference", header: "Mandate Reference" },
  { key: "mandateDate", header: "Mandate Date" },
  { key: "mandateStatus", header: "Mandate Status" },
  { key: "phoneNumber", header: "Phone Number" },
  { key: "updatedAt", header: "Timestamp" },
];

const UserMandatesTile = ({ userId }) => {
  const [{ token }] = React.useContext(AuthContext);
  const [data, setData] = React.useState([]);
  const [totalItems, setTotalItems] = React.useState(0);

  const paginate = ({ page, pageSize }) => {
    adminService
      .post("/fetchUserMandates", {
        token,
        userId,
        offset: (page - 1) * pageSize,
        limit: pageSize,
      })
      .then((data) => {
        if (data.ok) {
          setTotalItems(data.rowLength);
          setData(data.rows);
        }
      });
  };

  React.useEffect(() => {
    const fetchMandates = () => {
      adminService
        .post("/fetchUserMandates", { token, userId })
        .then((data) => {
          if (data.ok) {
            setTotalItems(data.rowLength);
            setData(data.rows);
          }
        });
    };
    fetchMandates();
  }, [token, userId]);

  return (
    <Tile className="user-detail__tile_payments">
      <h3 className="user-detail__tile_header">Mandates</h3>
      <div className="user-payment__table">
        {totalItems > 0 && (
          <>
            <MandatesTable
              key="users"
              size="normal"
              headers={headers}
              data={data}
            />
            <Pagination
              onChange={paginate}
              pageSize={10}
              totalItems={totalItems}
              page={1}
              pageSizes={[10, 20, 30, 40, 50]}
            />
          </>
        )}
        {totalItems === 0 && (
          <p className="user-detail__no_items">
            There are not mandates for this user yet!
          </p>
        )}
      </div>
    </Tile>
  );
};

export default UserMandatesTile;
