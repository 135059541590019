import React from 'react';

import { AreaChart } from '@carbon/charts-react';

const data = [
	{
		group: 'Active Pools',
		date: '2021-01-01T00:00:00.000Z',
		value: 10000,
	},
	{
		group: 'Active Pools',
		date: '2021-02-06T00:00:00.000Z',
		value: 25000,
	},
	{
		group: 'Active Pools',
		date: '2021-03-08T00:00:00.000Z',
		value: 57000,
	},
	{
		group: 'Active Pools',
		date: '2021-04-15T00:00:00.000Z',
		value: 80000,
	},
	{
		group: 'Active Pools',
		date: '2021-05-19T00:00:00.000Z',
		value: 201045,
	},
	{
		group: 'Active Users',
		date: '2021-01-01T00:00:00.000Z',
		value: 2500,
	},
	{
		group: 'Active Users',
		date: '2021-02-05T00:00:00.000Z',
		value: 5000,
	},
	{
		group: 'Active Users',
		date: '2021-03-08T00:00:00.000Z',
		value: 10000,
	},
	{
		group: 'Active Users',
		date: '2021-04-13T00:00:00.000Z',
		value: 25000,
	},
	{
		group: 'Active Users',
		date: '2021-05-19T00:00:00.000Z',
		value: 63000,
	},
];

const options = {
	title: 'Platform Growth',
	axes: {
		bottom: {
			title: '2021 Growth Figures',
			mapsTo: 'date',
			scaleType: 'time',
		},
		left: {
			mapsTo: 'value',
			scaleType: 'linear',
		},
	},
	curve: 'curveNatural',
	height: '300px',
};

const LineGraph = () => {
	return <AreaChart data={data} options={options}></AreaChart>;
};
export default LineGraph;
