import React from 'react';
import { Tag } from 'carbon-components-react';

const Status = {
	0: (
		<Tag type="gray" className="susu__status_tag">
			unknown
		</Tag>
	),
	1: (
		<Tag type="purple" className="susu__status_tag">
			initialized
		</Tag>
	),
	2: (
		<Tag type="cyan" className="susu__status_tag">
			ready
		</Tag>
	),
	3: (
		<Tag type="teal" className="susu__status_tag">
			started
		</Tag>
	),
	4: (
		<Tag type="green" className="susu__status_tag">
			activated
		</Tag>
	),
	5: (
		<Tag type="red" className="susu__status_tag">
			closed
		</Tag>
	),
	6: (
		<Tag type="magenta" className="susu__status_tag">
			unknown
		</Tag>
	),
};

const PoolStatus = ({ value }) => {
	return Status[value];
};

export default PoolStatus;
