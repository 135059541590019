import React from 'react';
import { Tag } from 'carbon-components-react';

const Type = {
	0: (
		<Tag type="gray" className="payment__type_tag">
			unknown
		</Tag>
	),
	1: (
		<Tag type="blue" className="payment__type_tag">
			deposit
		</Tag>
	),
	2: (
		<Tag type="teal" className="payment__type_tag">
			payout
		</Tag>
	),
};

const PaymentType = ({ value }) => {
	return Type[value];
};

export default PaymentType;
