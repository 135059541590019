import React from 'react';

import { auth, authPersistence } from '../services';

const LOGIN = 'LOGIN';
const LOGOUT = 'LOGOUT';
const ERROR = 'ERROR';
const LOADING = 'LOADING';

const actions = {
	LOGIN,
	LOGOUT,
	ERROR,
	LOADING,
};

const initialState = {
	isLoading: false,
	token: null,
	user: null,
	isAuthenticated: false,
	error: null,
};

const reducer = (state, action) => {
	switch (action.type) {
		case actions.LOGIN:
			return {
				...state,
				isLoading: false,
				isAuthenticated: true,
				user: action.payload.user,
				token: action.payload.token,
				error: null,
			};
		case actions.LOGOUT:
			return {
				...state,
				isLoading: false,
				isAuthenticated: false,
				user: null,
				error: null,
			};
		case actions.ERROR:
			return {
				...state,
				isLoading: false,
				isAuthenticated: false,
				user: null,
				error: action.payload.error,
			};
		case actions.LOADING:
			return {
				...state,
				isLoading: true,
			};
		default:
			return state;
	}
};

const login = ({ dispatch }, { email, password }) => {
	dispatch({ type: actions.LOADING });
	auth.setPersistence(authPersistence.SESSION)
		.then(() => {
			auth.signInWithEmailAndPassword(email, password)
				.then((user) => {
					user.getIdToken().then((token) => {
						console.log('login success');
						dispatch({
							type: actions.LOGIN,
							payload: { user, token },
						});
					});
				})
				.catch((error) => {
					console.log('log in failed');
					dispatch({ type: actions.ERROR, payload: { error } });
				});
		})
		.catch((error) => {
			console.log('auth persistence failed');
			dispatch({ type: actions.ERROR, payload: { error } });
		});
};

const logout = ({ dispatch }) => {
	auth.signOut()
		.then(() => {
			console.log('log out success');
			dispatch({ type: actions.LOGOUT, payload: {} });
		})
		.catch((error) => {
			console.log('log out failed');
			dispatch({ type: actions.ERROR, payload: { error } });
		});
};

const subscribe = ({ dispatch }) => {
	dispatch({ type: actions.LOADING });
	auth.onAuthStateChanged((user) => {
		if (user) {
			user.getIdToken().then((token) => {
				console.log('user logged in');
				dispatch({
					type: actions.LOGIN,
					payload: { user, token },
				});
			});
		} else {
			console.log('user not logged in');
			dispatch({ type: actions.ERROR, payload: { error: null } });
		}
	});
};

const AuthContext = React.createContext();
const AuthProvider = ({ children }) => {
	const [state, dispatch] = React.useReducer(reducer, initialState);
	return <AuthContext.Provider value={[state, dispatch]}>{children}</AuthContext.Provider>;
};

export { AuthContext, AuthProvider, login, logout, subscribe };
